import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
const TableFooter = ({ itemToDisplay, onFilter, paging }) => {
    const [currPage, setCurrPage] = useState(1)
    const [itemPaging, setItemPaging] = useState(2)
    useEffect(() => {
        if (paging) {
            setCurrPage(paging.pageNumber)
        }
    }, [paging])
    const handleChangePage = (page) => {
        let newPaging = {
            ...paging,
            pageNumber: page
        }
        onFilter(newPaging)
    }

    const handleChangePageSize = (pageSize) => {
        let newPaging = {
            ...paging,
            pageNumber: 1,
            pageSize: pageSize
        }
        onFilter(newPaging)
    }
    useEffect(() => {
        if (itemToDisplay && paging) {
            if (itemToDisplay >= paging.totalPages) {
                setItemPaging(1)
            } else {
                setItemPaging(itemToDisplay)
            }
        }
    }, [paging])
    return (
        <TableFooterWrapper>
            {paging ? (
                <React.Fragment>
                    <div className='control__page'>
                        <span className='arrow__item' onClick={() => handleChangePage(1)}>&#8810;</span>
                        <span className='arrow__item' onClick={() => currPage > 1 ? handleChangePage(currPage - 1) : null}>&lt;
                        </span>

                        {Array.apply(null, Array(itemPaging)).map((item, idx) => {
                            if (idx < paging.totalPages) return <div key={idx}
                                className={`${idx === 0 ? 'pagenumber active' : 'pagenumber'}`}
                                onClick={() => currPage < paging.totalPages ? handleChangePage(currPage + idx) : null}>{currPage + idx}</div>
                            else return null
                        }
                        )}

                        <span className='arrow__item' onClick={() => currPage < paging.totalPages ? handleChangePage(currPage + 1) : null}>&gt;</span>
                        <span className='arrow__item' onClick={() => handleChangePage(paging.totalPages)}>
                            &#8811;
                        </span>

                    </div>
                    <div className='pagesize'>
                        <label>Hiển thị </label>
                        <select onChange={(e) => handleChangePageSize(e.target.value)} value={paging.pageSize}>
                            <option value="10" key="1">10</option>
                            <option value="20" key="12">20</option>
                            <option value="50" key="13">50</option>
                        </select>
                    </div>
                </React.Fragment>
            ) : ''}
        </TableFooterWrapper>
    )
}


const TableFooterWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
padding: 10px 10px;
.control__page{
    display: flex;
    flex-basis: 60%;
    padding-right: 5%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .pagenumber{
        background-color: transparent;
        border-radius: 50%;
        color: #000;
        padding: 1px 7px;
        cursor: pointer;
        margin: 0 5px;
    }
    .pagenumber.active{
        background: #000;
        color: #fff;
    }
    .arrow__item{
        padding: 2px 4px;
        cursor: pointer;
    }
}
.pagesize{
    flex-basis: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    label{
        margin: 0;
    }
    select{
        margin: 5px;
        width: 40px;
        border-radius: 10%;
    }
}
`


TableFooter.PropsTypes = {
    itemToDisplay: PropTypes.number,
    handleNext: PropTypes.func,
    handlePrev: PropTypes.func,
    handleToStart: PropTypes.func,
    handleToEnd: PropTypes.func,
    handleTableSize: PropTypes.func,
    totalRecord: PropTypes.number

}

export default TableFooter
