import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING, NOTIFY } from "../based/Constants";
import { Notify } from "../based/Notify";
import PushingServices from "../based/services/PushingServices";
import VideoModal from "./VideoModal";
import VideoPushModal from "./VideoPushModal";

const agePushingVideo = {
    id: 0, // long
    url: "", // string
    thumbnail: "", // string
    isDefault: false, // bool
    isDelete: false, // bool
};

function VideoManagement(props) {
    const [activeFolder, setActiveFolder] = useState(0);
    const [datas, setDatas] = useState([agePushingVideo]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 50 });
    const [isShow, setIsShow] = useState(false);
    const [photoshopId, setPhotoshopId] = useState(0);
    const [videoId, setVideoId] = useState(0);
    const [isShowVideoPush, setIsShowVideoPush] = useState(false);

    useEffect(() => {
        _getData(paging);
    }, []);

    async function _getData(page) {
        let [err, data] = await PushingServices.GetVideoByPaging({ ...page });
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        } else {
            setDatas([]);
            setPaging(page);
        }
    }

    async function deleteVideo(id) {
        let [err, data] = await PushingServices.SetIsDeleteVideo(id);
        if (!err) {
            _getData(paging);
        } else {
            Notify(NOTIFY.ERROR, "Xóa thất bại", "Xóa thất bại");
        }
    }

    async function initVideoUploadShopee(id) {
        let [err, data] = await PushingServices.InitVideoUploadShopee(id);
        if (!err) {
            _getData(paging);
        } else {
            Notify(NOTIFY.ERROR, "Xóa thất bại", "Xóa thất bại");
        }
    }

    function _renderAction() {
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={(e) => {
                        setIsShow(true);
                        setVideoId(0);
                    }}
                >
                    Thêm mới
                </button>
            </React.Fragment>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center">STT</th>
                    <th className="center">Thumbnail</th>
                    <th className="center">Url</th>
                    <th className="center">Mặc định</th>
                    <th className="center">Shopee Video ID</th>
                    <th className="center"></th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td className="center">{idx + 1}</td>
                                <td className="center-image">
                                    <img src={item.thumbnail} />
                                </td>
                                <td className="center">
                                    <a
                                        className="text text-custom"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(item.url, "_blank");
                                        }}
                                    >
                                        {item.url}
                                    </a>
                                </td>
                                <td className="center">{item.isDefault ? "Mặc định" : ""}</td>
                                <td className="center">
                                    {item.pushingVideoPlatforms &&
                                        item.pushingVideoPlatforms.map((x, i) => {
                                            return (
                                                <div key={i}>
                                                    {x.platformName}/{x.shopId} : {x.platformVideoId}
                                                </div>
                                            );
                                        })}
                                </td>
                                <td className="center">
                                    <button
                                        className="btn btn-danger m-1"
                                        onClick={(e) => {
                                            deleteVideo(item.id);
                                        }}
                                    >
                                        Xóa
                                    </button>
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={(e) => {
                                            setIsShow(true);
                                            setVideoId(item.id);
                                        }}
                                    >
                                        Chỉnh sửa
                                    </button>
                                    <button
                                        className="btn btn-custom m-1"
                                        onClick={(e) => {
                                            setVideoId(item.id);
                                            setIsShowVideoPush(true);
                                        }}
                                    >
                                        Upload Video tới Shopee
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Quản lý Video Mặc định" />
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            <VideoModal
                show={isShow}
                onClose={() => setIsShow(false)}
                videoId={videoId}
                onSave={() => {
                    _getData(paging);
                    setIsShow(false);
                }}
            />
            <VideoPushModal
                show={isShowVideoPush}
                onClose={() => setIsShowVideoPush(false)}
                videoId={videoId}
                onSave={() => {
                    _getData(paging);
                    setIsShowVideoPush(false);
                }}
            />
        </Wrapper>
    );
}

export default VideoManagement;

const Wrapper = styled.div`
    td.center-image {
        width: 200px;
        height: 200px;
    }

    .center-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
