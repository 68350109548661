import { PLATFORM, STATUS_PRODUCT } from "../based/Constants";
import { DropdownModel } from "../based/PropsDefined";

export const PackageType = {
    TShirt: 1,
    Case: 2,
    Bottle: 3,
    Pencase: 4,
    Sticker: 5,
};
export class ProductItemModel {
    constructor() {
        this.productId = 0;
        this.isActive = true;
        this.name = "";
        this.sellerSku = "";
        this.barcode = "";
        this.standardPrice = 0;
        this.minimunPrice = 0;
        this.maximumPrice = 0;
        this.quantity = 0;
        this.images = [];
        this.avatar = "";
        this.optionsValue = "";
        this.listImage = [new ImageModel()];
    }
}

export class MainProductsModel {
    constructor() {
        //Section Basic Info
        this.id = 0;
        this.name = "";
        this.categoryId = 0;
        this.brandId = 0;
        this.originId = 0;
        this.isActive = true;
        this.isHot = true;
        this.isNewArrival = true;
        this.hasSerialNumber = false;
        this.hasExpDate = false;
        this.hasImei = false;
        this.productMaterialType = 2;
        this.productTypeId = 2;
        this.platenSize = 0;
        this.material = "100% Cotton";
        this.weightMaterial = "200g";

        //Section Description
        this.content = "";
        this.description = "";
        this.advantages = ""; //đặc điểm nổi bật
        this.listImage = [];
        this.images = [];

        //Section Price
        this.importPrice = 0;
        this.standardPrice = 0;

        //Shipping Attribute
        this.width = 0; //chiều rộng
        this.height = 0; //chiều cao
        this.length = 0; //chiều dài
        this.shippingWeight = 0; //khối lượng kiện hàng
        this.volume = 0; //thể tích kiện hàng

        //Seo
        this.seoMetaTitle = "";
        this.seoMetaKeywords = "";
        this.seoMetaDescription = "";

        //Display Field
        this.categoryName = "";
        this.brandName = "";
        this.productAvatar = "";
        this.attributes = [];
        this.variants = [];
        this.warranty = null;

        this.videoUrl = "";
        this.isShowHomePage = true;
        //ngành hàng và thuộc tính
        //mô tả chi tiết

        //giá & hàng tồn
        this.attributesVariant = [];
        this.sellerSKU = "";
        this.barcode = "";
        this.quantity = 0;
        this.multiVariant = true;
        this.appProductId = 0;
        this.mockup = [];

        //có update thông tin thay đổi artwork không
        this.isRunServices = false;
    }
}

export class ProductVariantModel {
    constructor() {
        this.attributes = [new DropdownModel()];
        this.sKU = "";
        this.barcode = "";
        this.id = 0;
        this.isActive = true;
        this.productId = 0;
        this.name = "";
        this.avatar = "";
        this.code = "";
        this.model = "";
        this.url = "";
        this.length = 0; //chiều dài
        this.width = 0; //chiều rộng
        this.height = 0; //chiều cao
        this.shippingWeight = 0; //khối lượng kiện hàng
        this.volume = 0; //thể tích kiện hàng
        this.status = STATUS_PRODUCT.NEW;
        this.createdDateStr = "";
        this.quantityMapped = 0;
        this.warehouseId = 0;
        this.optionsValue = [];
        this.images = [];
        this.packageType = 1;
        //giá
        this.sellPrice = 0;
        this.importPrice = 0;
        this.standardPrice = 0;
        this.voucherPrice = 0;
        this.variantCount = 0;
        this.isModified = false;
        this.listImage = [];
        this.frameConfigIgnorePixel = {};
    }
    listImage = [new ImageModel()];
    frameConfigIgnorePixel = new FrameConfigIgnorePixel();
}

export class VariantWarningZoneModel {
    constructor() {
        this.id = 0;
        this.imageId = 0;
        this.productVariantName = "";
        this.url = "";
        this.topLayer = "";
        this.bottomLayer = "";
        this.displayName = "";
    }
}

export class FrameConfigIgnorePixel {
    constructor() {
        this.attributeValueId = 0;
        this.ignorePixelUrl = 0;
        this.widthFrame = 0;
        this.heightFrame = 0;
    }
}

export class VariantImageModel {
    constructor() {
        this.id = 0;
        this.imageId = 0;
        this.imageVariantUrl = "";
        this.index = 0;
        this.coverUrl = "";
    }
}

export class ProductWarranty {
    constructor() {
        this.productId = 0;
        this.warrantyId = 0;
        this.warrantyTime = 0;
        this.warrantyPhone = "";
        this.warrantyAddress = "";
        this.warrantyContent = "";
    }
}

export class RewardPoint {
    constructor() {
        this.productId = 0;
        this.rewardPoint = 0;
        this.commission = 0;
    }
}

export class ProductDiscounts {
    constructor() {
        this.productId = 0;
        this.fromDate = "";
        this.toDate = "";
        this.productDiscountsDetail = [];
    }
}

export class ProductDiscountsDetailModel {
    constructor() {
        this.productId = 0;
        this.discountLevel = 1;
        this.discountValue = 0;
    }
}

export class ProductsMapping {
    constructor() {
        this.agencyMainProductId = 0;
        this.productVariantId = 0;
        this.quantityMapping = 0;
    }
}

export class OPFWarehouseModel {
    constructor() {
        this.productId = 0;
        this.quantity = 0;
        this.warehouseId = 0;
        this.productType = 0;
        this.platform = PLATFORM.LOTUS;
    }
}

export class ImageModel {
    constructor() {
        this.id = 0;
        this.url = "";
        this.objectId = 0;
        this.imageSite = 0;
        this.imageType = 0;
        this.urlRedirect = "";
        this.listWarningZone = [];
        this.listVariantImage = [];
    }
}
