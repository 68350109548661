import React, { useEffect, useState } from "react";
import NumberFormat from "react-currency-format";
import Sticky from "react-stickynode";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import { NOTIFY, PAYMENT_METHOD, PRINT_TYPE, STATUS_TRANSACTION } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import Request from "../based/Request";
import OrderServices from "../based/services/OrderServices";
import AttributeValue from "../campaign-milestone/components/AttributeValue";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";
import CustomizeDetailModal from "./CustomizeDetailModal";
import CancelModal from "./CancelModal";
import QRModal from "./QRModal";

const OperatorOrderType = {
    Creator: 0,
    Customize: 1,
};
const PreProductionType = {
    PrintshopHandle: 0,
    RanusHandle: 1,
};
const VerifyOrderProductModel = {
    orderProductId: 0,
    operatorOrderType: OperatorOrderType.Creator,
    preProductionType: PreProductionType.PrintshopHandle,
};

const DesignStatus = {
    Normal: 0,
    Enhance: 1,
};

export default function OrderDetail(props) {
    const [order, setOrder] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu");
    const { orderId } = props.match.params;
    const [showCustomizeDetailModal, setShowCustomizeDetailModal] = useState(false);
    const [orderProductId, setOrderProductId] = useState(0);
    const [showChangePaymentMethodModal, setShowChangePaymentMethodModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    //show qr modal
    const [showQRModal, setShowQRModal] = useState(false);

    useEffect(() => {
        let paramId = props.match.params.orderId;
        if (paramId > 0) {
            getOrderDetail(paramId);
        }
    }, []);

    async function getOrderDetail(orderId) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        const [err, data] = await OrderServices.GetOrderDetail(orderId);
        if (!err) {
            setOrder(data);
            setIsLoading(false);
            setMsg(null);
        } else {
            setOrder({});
            setIsLoading(false);
            setMsg(null);
        }
    }

    async function updateOrderStatusById(orderId) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await OrderServices.UpdateOrderStatusById(orderId);
        if (!err) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Chuyển trạng thái thành công");
            await getOrderDetail(orderId);
        } else {
            let errorData = err.errors;
            let msg = "Chuyển trạng thái thất bại";
            if (errorData && errorData.length > 0) {
                msg = errorData[0].value;
            }
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
        setIsLoading(false);
        setMsg(null);
    }

    async function verifyOrderProduct(model) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await OrderServices.VerifyOrderProduct(model);
        if (!err) {
            await getOrderDetail(orderId);
        } else {
            let errorData = err.errors;
            let msg = "Chuyển trạng thái thất bại";
            if (errorData && errorData.length > 0) {
                msg = errorData[0].value;
            }
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
        setIsLoading(false);
        setMsg(null);
    }

    const _handleRenderEnhance = async (orderProductId, printType) => {
        //call api chuyển trạng thái đơn hàng
        let preProductionType = printType == PRINT_TYPE.DTG ? PreProductionType.PrintshopHandle : PreProductionType.RanusHandle;
        await verifyOrderProduct({ orderProductId: orderProductId, operatorOrderType: OperatorOrderType.Customize, preProductionType: preProductionType });

        //sau đó tiến hành render lại artwork
        let [err, data] = await OrderServices.RenderArtworkWithEnhanceUrl(orderProductId);
    };

    async function momoRefund(orderId) {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await OrderServices.MomoRefund(orderId);
        if (!err) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Hoàn tiền thành công");
            await getOrderDetail(orderId);
        } else {
            let errorData = err.errors;
            let msg = "Hoàn tiền thất bại";
            if (errorData && errorData.length > 0) {
                msg = errorData[0].value;
            }
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, msg);
        }
        setIsLoading(false);
        setMsg(null);
    }

    function _renderAttributes(item) {
        const attrArr = Common.GroupArray(item.attributeValues, "name");
        for (const key of attrArr.keys()) {
            let value = attrArr.get(key);
            const upgradeListAtt = [...new Map(value.map((item) => [item["value"], item])).values()];
            attrArr.set(key, upgradeListAtt);
        }
        const attrObj = {};
        for (const key of attrArr.keys()) {
            attrObj[key] = attrArr.get(key);
            for (const attr of attrObj[key]) {
                for (const ageProVarAtt of item.attributeValues) {
                    if (attr.value === ageProVarAtt.value) {
                        attr.checked = true;
                        break;
                    } else attr.checked = false;
                }
            }
        }

        if (item.printTypeName) attrObj["Print Type"] = [{ value: item.printTypeName }];
        if (item.printSideName) attrObj["Print Side"] = [{ value: item.printSideName }];
        return attrObj && <div className="d-flex flex-row flex-wrap">{Object.keys(attrObj).map((key) => attrObj[key] && attrObj[key].map((item, idx) => <AttributeValue key={idx} keyName={key} value={item.value} />))}</div>;
    }

    function _notReadyProduction() {
        let containPetCustomize = order && order.orderCustomizeProducts && order.orderCustomizeProducts.some((x) => !x.operatorOrderId);
        return containPetCustomize;
    }

    function _isDisableProcess(product) {
        //nếu đơn bình thường thì render ra artwork url thì mới cho xử lý
        if (product.designStatus == DesignStatus.Normal && !product.artworkUrl) {
            return true;
        }
        //nếu đơn được enhance thì enhanceall bằng true thì mới cho xử lý và đã render ra file artwork url rồi
        if (product.designStatus == DesignStatus.Enhance && !product.isEnhanceAll && !product.artworkUrl) {
            return true;
        }
        return false;
    }

    return (
        <React.Fragment>
            <Breadcrumbs currentPage=" Chi tiết đơn hàng" parentPage=" Quản lý đơn hàng" />
            <Loading show={isLoading} msg={msg} />

            {order ? (
                <Wrapper>
                    <Sticky enabled={true} top={70} bottomBoundary={4000} innerZ={2}>
                        <div className="form-group product-navigate-buttons">
                            {order && order.status == STATUS_TRANSACTION.PROCESSING && (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        className={`btn btn-custom waves-effect w-xs waves-light btn-sm m-1 ${_notReadyProduction() ? "disabled" : ""}`}
                                        onClick={() => {
                                            updateOrderStatusById(orderId);
                                        }}
                                    >
                                        Chuyển sản xuất/Trạng thái
                                    </button>
                                </React.Fragment>
                            )}
                            {order && (order.paymentMethod == PAYMENT_METHOD.COD || order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER) && (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        className="btn btn-custom waves-effect w-xs waves-light btn-sm m-1"
                                        onClick={(e) => {
                                            setShowChangePaymentMethodModal(true);
                                        }}
                                    >
                                        Khách đã thanh toán
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-custom waves-effect w-xs waves-light btn-sm m-1"
                                        onClick={(e) => {
                                            setShowQRModal(true);
                                        }}
                                    >
                                        Tạo QR Đặt cọc tay
                                    </button>
                                </React.Fragment>
                            )}

                            <button
                                type="button"
                                className="btn btn-danger waves-effect w-xs waves-light btn-sm m-1"
                                onClick={(e) => {
                                    setShowCancelModal(true);
                                }}
                            >
                                Hủy đơn trực tiếp
                            </button>
                            {order && order.status == STATUS_TRANSACTION.CANCELED && order.transactionId > 0 && !order.isRefund && (
                                <button
                                    type="button"
                                    className="btn btn-custom waves-effect w-xs waves-light btn-sm m-1"
                                    onClick={(e) => {
                                        let paramId = props.match.params.orderId;
                                        momoRefund(paramId);
                                    }}
                                >
                                    Hoàn tiền
                                </button>
                            )}
                            <div className="sub-header">
                                <p>
                                    - Đơn hàng chỉ được chuyển trạng thái sang sản xuất khi đã <b>Xác nhận đẩy đủ phương thức dưới mục Hành Động</b>{" "}
                                </p>
                                <p>
                                    - Những đơn hàng tự thiết kế chỉ sẵn sàng khi hệ thống render ra <b>Thiết kế chất lượng cao</b>
                                </p>
                                <p>
                                    - Có 2 phương thức xử lý đơn hàng: <b>Chuyển đơn trực tiếp (DTG/PET/UV)</b> hoặc <b>Enhance và chuyển đơn</b>
                                </p>
                                <p>
                                    - <b>Enhance và chuyển đơn:</b> Chọn ảnh muốn enhance {`>>`} Chờ hệ thống enhance hình {`>>`} Chờ hệ thống render thiết kế {`>>`} Chuyển đơn sản xuất
                                </p>
                            </div>
                        </div>
                    </Sticky>
                    <div className="card-box padding-10">
                        <div className="col-sm-12 p-0 form-row">
                            <div className="col-sm-3">
                                <div className="order-information">
                                    <div className="information-title">Thông tin chung</div>
                                    <div className="order-detail-list">
                                        <div className="order-detail-item">
                                            <span>Số đơn hàng</span>
                                            <label className="col-form-label">{order.id} </label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Mã đơn hàng</span>
                                            <label className="col-form-label">{order.orderNumber} </label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Ngày đặt</span>
                                            <label className="col-form-label">{Common.formatDate(new Date(order.createdDate))} </label>
                                        </div>
                                        {order && order.customer && (
                                            <React.Fragment>
                                                <div className="order-detail-item">
                                                    <span>Tên tài khoản</span>
                                                    <label className="col-form-label">{order.customer.fullName} </label>
                                                </div>
                                                <div className="order-detail-item">
                                                    <span>Email</span>
                                                    <label className="col-form-label">{order.customer.email} </label>
                                                </div>
                                                <div className="order-detail-item">
                                                    <span>Số điện thoại</span>
                                                    <label className="col-form-label">{order.customer.phoneNumber} </label>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        <div className="order-detail-item">
                                            <span>Trạng thái đơn</span>
                                            <label className="col-form-label color-green">{order.statusName} </label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>ĐVVC</span>
                                            <label className="col-form-label">{order.shippingCompanyName} </label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Mã vận chuyển</span>
                                            <label className="col-form-label">{order.shippingOrderNumber} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="order-information">
                                    <div className="information-title">Thông tin thanh toán</div>
                                    <div className="order-detail-list">
                                        <div className="order-detail-item">
                                            <span>Thành tiền</span>
                                            <label className="col-form-label">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={order.totalPrice} />}</label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Tiền giảm giá</span>
                                            <label className="col-form-label">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={order.totalDiscount} />}</label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Phí vận chuyển</span>
                                            <label className="col-form-label">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={order.shippingFee} />}</label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Phí đóng gói</span>
                                            <label className="col-form-label color-green">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={order.packageCost} />}</label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Tổng tiền thanh toán</span>
                                            <label className="col-form-label">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={order.finallyPrice} />}</label>
                                        </div>
                                        <div className="order-detail-item">
                                            <span>Phương thức thanh toán</span>
                                            <label className="col-form-label">{order.paymentMethodName}</label>
                                        </div>
                                        {order.transferPartial > 0 && (
                                            <div className="order-detail-item">
                                                <span>Chuyển khoản trước</span>
                                                <label className="col-form-label color-green">
                                                    {<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} suffix=" đ" value={order.transferPartial} />}
                                                    {order.transcriptUrl && (
                                                        <div
                                                            className="icon"
                                                            onClick={(e) => {
                                                                window.open(order.transcriptUrl, "_blank");
                                                            }}
                                                        >
                                                            <PictureIcon />
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        )}
                                        {order.transactionId > 0 && (
                                            <div className="order-detail-item">
                                                <span>Mã thanh toán</span>
                                                <label className="col-form-label">{order.transactionId}</label>
                                            </div>
                                        )}
                                        {order.transactionId > 0 && (
                                            <div className="order-detail-item">
                                                <span>Thông tin hoàn tiền (Refund)</span>
                                                <label className="col-form-label">{order.isRefund ? "Đã hoàn tiền" : "Chưa hoàn tiền"}</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="order-information">
                                    <div className="information-title">Địa chỉ giao hàng và hóa đơn</div>
                                    {order.orderShipping && (
                                        <div className="order-detail-list">
                                            <div className="order-detail-item">
                                                <span>Tên người nhận</span>
                                                <label className="col-form-label">{order.orderShipping.userName} </label>
                                            </div>
                                            <div className="order-detail-item">
                                                <span>Số điện thoại</span>
                                                <label className="col-form-label">{order.orderShipping.phoneNumber} </label>
                                            </div>
                                            <div className="order-detail-item">
                                                <span>Email người nhận</span>
                                                <label className="col-form-label">{order.orderShipping.email} </label>
                                            </div>
                                            <div className="order-detail-item">
                                                <span>Địa chỉ chi tiết</span>
                                                <label className="col-form-label">{order.orderShipping.fullAddress} </label>
                                            </div>
                                        </div>
                                    )}

                                    {order.orderBilling && (
                                        <div className="order-detail-list">
                                            <div className="order-detail-item">
                                                <span>Loại hóa đơn</span>
                                                <label className="col-form-label">Công ty</label>
                                            </div>
                                            <div className="order-detail-item">
                                                <span>Tên công ty</span>
                                                <label className="col-form-label">{order.orderBilling.companyName}</label>
                                            </div>
                                            <div className="order-detail-item">
                                                <span>Email</span>
                                                <label className="col-form-label">{order.orderBilling.email}</label>
                                            </div>
                                            <div className="order-detail-item">
                                                <span>Địa chỉ</span>
                                                <label className="col-form-label">{order.orderBilling.address}</label>
                                            </div>
                                            <div className="order-detail-item">
                                                <span>Mã số thuế</span>
                                                <label className="col-form-label">{order.orderBilling.taxCode}</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-box padding-10">
                        <div className="col-md-12 row">
                            <h4 className="header-title mx-0"> SẢN PHẨM</h4>
                            <div className="col-sm-12 p-0">
                                <div className="content-table table-rep-plugin full-width">
                                    <table className="table table-bordered m-b-0 focus-on">
                                        <thead>
                                            <tr>
                                                <th width="200" className="cursor-pointer center">
                                                    <span className="ml-3">Hình ảnh</span>
                                                </th>
                                                <th width="150" className="cursor-pointer center">
                                                    <span className="ml-3">Sản phẩm</span>
                                                </th>

                                                <th width="100" className="cursor-pointer center">
                                                    <span className="ml-3">Giá (đ)</span>
                                                </th>
                                                <th width="100" className="cursor-pointer center">
                                                    <span className="ml-3">Số lượng</span>
                                                </th>
                                                <th width="100" className="cursor-pointer center">
                                                    <span className="ml-3">Giảm giá (đ)</span>
                                                </th>
                                                <th width="100" className="cursor-pointer center">
                                                    <span className="ml-3">Tổng cộng (đ)</span>
                                                </th>
                                                <th width="100" className="cursor-pointer center">
                                                    <span className="ml-3">Link thiết kế</span>
                                                </th>
                                                <th width="100" className="cursor-pointer center">
                                                    <span className="ml-3">Loại sản phẩm</span>
                                                </th>
                                                <th width="150" className="cursor-pointer center">
                                                    <span className="ml-3">Hành động</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order.orderProducts &&
                                                order.orderProducts.length > 0 &&
                                                order.orderProducts.map((product, k) => {
                                                    return (
                                                        <tr key={k}>
                                                            <td className="vertical-align-middle text-center">
                                                                <div className="image-front">
                                                                    <img src={product.productImageUrl} />
                                                                </div>
                                                            </td>
                                                            <td className="vertical-align-middle">
                                                                <strong> {product.productName} </strong>
                                                                <p className="mb-0">
                                                                    <small className="font-italic">Mã SKU: {product.productSku}</small>
                                                                </p>
                                                                {product.attributeValues && product.attributeValues.length > 0 && _renderAttributes(product)}
                                                                <p className="mb-0 font-weight-bold color-green">In {product.printTypeName}</p>
                                                            </td>
                                                            <td className="vertical-align-middle text-center">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={product.price} />}</td>
                                                            <td className="vertical-align-middle text-center">{product.quantity}</td>
                                                            <td className="vertical-align-middle text-center">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={product.discount} />}</td>
                                                            <td className="vertical-align-middle text-center">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={product.finallyPrice} />}</td>
                                                            <td className="vertical-align-middle text-center">
                                                                <a
                                                                    onClick={(e) => {
                                                                        window.open(`${Request.HOME_URL}/product-detail-page/${product.setId}/${product.agencyProductVariantId}`, "_blank");
                                                                    }}
                                                                >
                                                                    {`${Request.HOME_URL}/product-detail-page/${product.setId}/${product.agencyProductVariantId}`}
                                                                </a>
                                                            </td>
                                                            <td className="vertical-align-middle text-center">
                                                                <span className="badge badge-success brand">Thương hiệu</span>
                                                            </td>
                                                            {/* tạo button để verify sản phẩm đơn hàng */}
                                                            <td className="vertical-align-middle">
                                                                {!product.operatorOrderId && order && order.status == STATUS_TRANSACTION.PROCESSING && (
                                                                    <React.Fragment>
                                                                        {product.printType == PRINT_TYPE.PET && (
                                                                            <button
                                                                                className="btn btn-primary btn-sm"
                                                                                onClick={(e) => {
                                                                                    const model = { ...VerifyOrderProductModel };
                                                                                    model.orderProductId = product.id;
                                                                                    model.operatorOrderType = OperatorOrderType.Creator;
                                                                                    model.preProductionType = PreProductionType.RanusHandle;
                                                                                    verifyOrderProduct(model);
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-check"></i> Xử lý bởi Ranus (PET)
                                                                            </button>
                                                                        )}
                                                                        {product.printType == PRINT_TYPE.DTG && (
                                                                            <button
                                                                                className="btn btn-primary btn-sm"
                                                                                onClick={(e) => {
                                                                                    const model = { ...VerifyOrderProductModel };
                                                                                    model.orderProductId = product.id;
                                                                                    model.operatorOrderType = OperatorOrderType.Creator;
                                                                                    model.preProductionType = PreProductionType.PrintshopHandle;
                                                                                    verifyOrderProduct(model);
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-check"></i> Xử lý bởi Xưởng in (DTG)
                                                                            </button>
                                                                        )}
                                                                        {product.printType == PRINT_TYPE.UV && (
                                                                            <button
                                                                                className="btn btn-primary btn-sm"
                                                                                onClick={(e) => {
                                                                                    const model = { ...VerifyOrderProductModel };
                                                                                    model.orderProductId = product.id;
                                                                                    model.operatorOrderType = OperatorOrderType.Creator;
                                                                                    model.preProductionType = PreProductionType.PrintshopHandle;
                                                                                    verifyOrderProduct(model);
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-check"></i> Xử lý bởi Xưởng in (UV)
                                                                            </button>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                                {product.operatorOrderId && product.operatorOrderId > 0 ? <span className="m-1 badge badge-info">TagId: {product.operatorOrderId}</span> : ""}

                                                                <span
                                                                    className={`m-1 badge badge-custom ${product.fileUrl ? "" : "disabled"}`}
                                                                    onClick={(e) => {
                                                                        window.open(product.fileUrl, "_blank");
                                                                    }}
                                                                >
                                                                    Download File
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {order.orderCustomizeProducts &&
                                                order.orderCustomizeProducts.length > 0 &&
                                                order.orderCustomizeProducts.map((product, k) => {
                                                    return (
                                                        <tr key={k}>
                                                            <td className="vertical-align-middle text-center">
                                                                {product.productImageFrontUrl && (
                                                                    <div className="image-front">
                                                                        <img src={product.productImageFrontUrl} />
                                                                    </div>
                                                                )}
                                                                {product.productImageBackUrl && (
                                                                    <div className="image-back">
                                                                        <img src={product.productImageBackUrl} />
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="vertical-align-middle">
                                                                <strong> {product.appProductName} </strong>
                                                                <div className="d-flex flex-row flex-wrap">
                                                                    <AttributeValue keyName={"Màu"} value={product.color} />
                                                                    <AttributeValue keyName={"Size"} value={product.size} />
                                                                </div>
                                                                <p className="mb-0 font-weight-bold color-green">In {product.printTypeName}</p>
                                                                {product.designStatus == DesignStatus.Enhance && <div className="enhance-content text-danger m-2">* Artwork đang có Element được Enhance</div>}
                                                            </td>

                                                            <td className="vertical-align-middle text-center">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={product.price} />}</td>
                                                            <td className="vertical-align-middle text-center">{product.quantity}</td>
                                                            <td className="vertical-align-middle text-center">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={product.discount} />}</td>
                                                            <td className="vertical-align-middle text-center">{<NumberFormat thousandSeparator="," displayType={"text"} decimalSeparator="." decimalScale={2} value={product.finallyPrice} />}</td>
                                                            <td className="vertical-align-middle text-center">
                                                                <a
                                                                    onClick={(e) => {
                                                                        window.open(`https://ranus.vn/print-editor/0/0/${product.groupDesignId}`, "_blank");
                                                                    }}
                                                                >
                                                                    {`https://ranus.vn/print-editor/0/0/${product.groupDesignId}`}
                                                                </a>
                                                            </td>
                                                            <td className="vertical-align-middle text-center">
                                                                <span className="badge badge-success customize">Tự thiết kế</span>
                                                            </td>

                                                            {/* tạo button để verify sản phẩm đơn hàng */}
                                                            <td className="vertical-align-middle">
                                                                <button
                                                                    className="btn btn-primary btn-sm m-2"
                                                                    onClick={(e) => {
                                                                        setShowCustomizeDetailModal(true);
                                                                        setOrderProductId(product.id);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-check"></i> Enhance Artwork
                                                                </button>
                                                                {!product.operatorOrderId && order && order.status == STATUS_TRANSACTION.PROCESSING && (
                                                                    <React.Fragment>
                                                                        {product.printType == PRINT_TYPE.PET && (
                                                                            <button
                                                                                className={`btn btn-primary btn-sm ${_isDisableProcess(product) ? "disabled" : ""}`}
                                                                                onClick={(e) => {
                                                                                    if (product.designStatus == DesignStatus.Normal) {
                                                                                        const model = { ...VerifyOrderProductModel };
                                                                                        model.orderProductId = product.id;
                                                                                        model.operatorOrderType = OperatorOrderType.Customize;
                                                                                        model.preProductionType = PreProductionType.RanusHandle;
                                                                                        verifyOrderProduct(model);
                                                                                    } else {
                                                                                        _handleRenderEnhance(product.id);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-check"></i> Xử lý bởi Ranus (PET)
                                                                            </button>
                                                                        )}
                                                                        {product.printType == PRINT_TYPE.DTG && (
                                                                            <button
                                                                                className={`btn btn-primary btn-sm ${_isDisableProcess(product) ? "disabled" : ""}`}
                                                                                onClick={(e) => {
                                                                                    if (product.designStatus == DesignStatus.Normal) {
                                                                                        const model = { ...VerifyOrderProductModel };
                                                                                        model.orderProductId = product.id;
                                                                                        model.operatorOrderType = OperatorOrderType.Customize;
                                                                                        model.preProductionType = PreProductionType.PrintshopHandle;
                                                                                        verifyOrderProduct(model);
                                                                                    } else {
                                                                                        _handleRenderEnhance(product.id);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-check"></i> Xử lý bởi Xưởng in (DTG)
                                                                            </button>
                                                                        )}
                                                                        {product.printType == PRINT_TYPE.UV && (
                                                                            <button
                                                                                className={`btn btn-primary btn-sm ${_isDisableProcess(product) ? "disabled" : ""}`}
                                                                                onClick={(e) => {
                                                                                    ConfirmDialog(
                                                                                        "Xác nhận ?",
                                                                                        "Bạn xác nhận xử lý bởi Ranus (UV)",
                                                                                        () => {
                                                                                            return new Promise(async (resolve, reject) => {
                                                                                                if (product.designStatus == DesignStatus.Normal) {
                                                                                                    const model = { ...VerifyOrderProductModel };
                                                                                                    model.orderProductId = product.id;
                                                                                                    model.operatorOrderType = OperatorOrderType.Customize;
                                                                                                    model.preProductionType = PreProductionType.RanusHandle;
                                                                                                    verifyOrderProduct(model);
                                                                                                } else {
                                                                                                    _handleRenderEnhance(product.id);
                                                                                                }
                                                                                                resolve({ title: "Thành công", msg: "Thành công." });
                                                                                            });
                                                                                        },
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-check"></i> Xử lý bởi Ranus (UV)
                                                                            </button>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                                {product.operatorOrderId > 0 && <span className="badge badge-info m-2">TagId: {product.operatorOrderId}</span>}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {order.orderGiftProducts &&
                                                order.orderGiftProducts.length > 0 &&
                                                order.orderGiftProducts.map((gift, k) => {
                                                    return (
                                                        <tr key={k}>
                                                            <td className="vertical-align-middle text-center">
                                                                <div className="image-front">
                                                                    <img src={gift.giftUrl} />
                                                                </div>
                                                            </td>
                                                            <td className="vertical-align-middle">
                                                                <strong>[Quà tặng] {gift.giftName} </strong>
                                                            </td>

                                                            <td className="vertical-align-middle"></td>
                                                            <td className="vertical-align-middle text-center">{gift.quantity}</td>
                                                            <td className="vertical-align-middle"></td>
                                                            <td className="vertical-align-middle"></td>
                                                            <td className="vertical-align-middle"></td>
                                                            <td className="vertical-align-middle text-center">
                                                                <span className="badge badge-success gift">Quà tặng</span>
                                                            </td>
                                                            <td className="vertical-align-middle"></td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            ) : (
                <div>Hiện tại không tồn tại</div>
            )}

            <CustomizeDetailModal
                show={showCustomizeDetailModal}
                onClose={(save) => {
                    setShowCustomizeDetailModal(false);
                    let paramId = props.match.params.orderId;
                    if (save && paramId > 0) {
                        getOrderDetail(paramId);
                    }
                }}
                orderProductId={orderProductId}
            />
            <ChangePaymentMethodModal
                show={showChangePaymentMethodModal}
                onClose={() => {
                    setShowChangePaymentMethodModal(false);
                }}
                onSave={() => {
                    setShowChangePaymentMethodModal(false);
                    getOrderDetail(orderId);
                }}
                orderId={orderId}
                initTransfer={order && order.transferPartial}
            />
            <CancelModal
                orderId={orderId}
                show={showCancelModal}
                onClose={() => {
                    setShowCancelModal(false);
                }}
            />
            <QRModal
                orderId={orderId}
                show={showQRModal}
                onClose={() => {
                    setShowQRModal(false);
                }}
                finallyPrice={order && order.finallyPrice}
            />
        </React.Fragment>
    );
}

const Wrapper = styled.div`
    td a {
        color: #13aaff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    span.badge {
        padding: 8px 10px;
        border-radius: 20px;
    }

    .customize {
        background: #ffe1ea;
        color: #ff004f;
    }

    .gift {
        background: #ffe1ea;
        color: #ff004f;
    }

    .brand {
        background: #fff2e3;
        color: #ff8a00;
    }

    .image-front img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .image-back img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .image-front {
        display: inline-block;
        width: 100px;
        height: 100px;
    }

    .image-back {
        display: inline-block;
        width: 100px;
        height: 100px;
    }
    .color-green {
        color: #64c5b1 !important;
    }
    label.col-form-label {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
    }
    .sub-header {
        margin-top: 8px;
    }

    .sub-header p {
        margin: 0px;
    }
    label.col-form-label {
        padding: 0 !important;
    }

    .order-detail-item {
        margin: 5px 0;
    }

    .order-detail-item {
        display: flex;
        align-items: baseline;
    }

    .order-detail-item span {
        flex-basis: 30%;
    }

    .order-detail-item label.col-form-label {
        flex: 1;
        color: #666f7b;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .col-form-label span {
        flex-basis: unset !important;
    }

    .order-detail-list {
        margin: 10px 0;
    }
`;

const PictureIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#64C5B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" stroke="#64C5B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 15L16 10L5 21" stroke="#64C5B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
