import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import TFUSelect from "../based/refactor/TFUSelect";
import Lazada from "./assets/Lazada.png";
import Shopee from "./assets/Shopee.png";
import Tiktok from "./assets/Tiktok.png";
import ActiveMark from "./assets/ActiveMark.png";
import { NOTIFY, PLATFORM } from "../based/Constants";
import PushingServices from "../based/services/PushingServices";
import Common from "../based/Common";
import { Notify } from "../based/Notify";

const Artwork_Type = {
    Front: 1,
    Back: 2,
};

const PushingProductMultiPlatformModel = {
    appCategoryId: 0,
    opfCategoryId: 0,
    platform: PLATFORM.Shopee,
    platformName: "",
    photoshopId: 0,
    platformBreadcrumb: "",
    photoshopFiles: [],
    photoshopProducts: [],
    shopOptions: [],
    shopId: "",
    listOPFCategory: [],
};

const FileType = {
    Normal: 0,
    Case: 1,
};

const PushingProductMultiModel = {
    id: 0,
    pushingProductId: 0,
    name: "",
    categoryId: 0,
    platformCategoryId: 0,
    platformBreadcrumb: "",
    brand: "Ranus",
    content: "",
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    printType: 0,
    photoshopId: 0,
    colorCode: "",
    colorName: "",
    appProductVariantId: "",
    platform: PLATFORM.Undefined,
    shopId: "",
    pushingProduct: {},
    pushingPhotoshopProducts: [],
    artworkType: Artwork_Type.Front,
    artworkCount: 1,
    multiPlatformModels: [PushingProductMultiPlatformModel],
    designId: 0,
    avatar: "",
    fileType: FileType.Normal,
};
import { useHistory, useLocation } from "react-router-dom";
function PushingMultiDetail(props) {
    const { designId } = props.match.params;
    const [model, setModel] = useState(PushingProductMultiModel);
    const history = useHistory();

    useEffect(() => {
        if (designId) {
            _getProductCustomizeMultiById(designId);
        }
    }, [designId]);

    const checkedPlatform = useMemo(() => {
        let md = { ...model };
        let platforms = md.multiPlatformModels;
        if (!platforms || platforms.length == 0) return null;
        let platform = platforms.find((x) => x.isActive);
        return platform;
    }, [model]);

    const checkedShop = useMemo(() => {
        let md = { ...model };
        let platforms = md.multiPlatformModels;
        for (var pl of platforms) {
            var shopOptions = pl.shopOptions;
            for (var shop of shopOptions) {
                if (shop.isActive) {
                    shop.platform = pl.platform;

                    //lấy danh sách ngành hàng
                    const listCat = pl.listOPFCategory.map((x) => {
                        return {
                            value: x.opfCategoryId,
                            label: x.platformBreadcrumb,
                        };
                    });
                    shop.listCat = listCat;

                    //lấy danh sách danh mục
                    const listShopCat = shop.listShopCatOption.map((x) => {
                        return {
                            value: x.id,
                            label: x.name,
                        };
                    });
                    shop.listShopCat = listShopCat;
                    return shop;
                }
            }
        }

        return null;
    }, [model]);

    async function _getProductCustomizeMultiById(designId) {
        let [err, data] = await PushingServices.GetProductCustomizesMultiById(designId);
        if (!err && data) {
            setModel(data);
        } else {
            setModel(null);
            Notify(NOTIFY.ERROR, "Lỗi", err.message);
        }
    }

    //lấy thông tin sản phẩm theo photoshopId
    async function _getPhotoshopProduct(md) {
        let [err, data] = await PushingServices.GetPushingPhotoshopProductDetail(md);
        if (!err && data) {
            data = data.map((item) => {
                item.checked = true;
                return item;
            });
            return data;
        } else {
            return [];
        }
    }

    async function _createUpdatePushProduct() {
        let md = { ...model };
        md.designId = +designId;
        md.multiPlatformModels = md.multiPlatformModels.filter((x) => x.shopOptions.some((y) => y.isChecked && y.photoshopProducts != null && y.photoshopProducts.length > 0));

        if (!md.multiPlatformModels || md.multiPlatformModels.length == 0) {
            Notify(NOTIFY.ERROR, "Lỗi", "Vui lòng chọn shop muốn đẩy");
            return;
        }

        if (md.multiPlatformModels && md.multiPlatformModels.length > 0) {
            for (var multi of md.multiPlatformModels) {
                multi.shopOptions = multi.shopOptions.filter((x) => x.isChecked);
                if (multi.shopOptions && multi.shopOptions.length > 0) {
                    for (var shop of multi.shopOptions) {
                        shop.photoshopProducts = shop.photoshopProducts.filter((x) => x.checked);
                    }
                }
            }
        }

        console.log("md", md);
        let [err, data] = await PushingServices.CreateUpdatePushProductMulti(md);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Đẩy sản phẩm thành công, hệ thống sẽ tự động cập nhật sản phẩm sau ít phút");
            setTimeout(() => {
                history.push("/pushing-manage");
            }, 1000);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", err.message);
        }
    }

    function getImage(platform) {
        switch (platform) {
            case PLATFORM.Shopee:
                return <img src={Shopee} />;
            case PLATFORM.Lazada:
                return <img src={Lazada} />;
            case PLATFORM.Tiktok:
                return <img src={Tiktok} />;
            default:
                return "";
        }
    }

    if (!model) return <div>Hiện tại không có dữ liệu</div>;
    return (
        <Wrapper>
            <section className="product_overview">
                <div className="avatar">
                    <img src={model.avatar} alt={model.name} />
                </div>
                <div className="overview-section">
                    <div className="overview-highlight">Sản phẩm muốn đẩy</div>
                    <div className="overview-title">{model.name}</div>
                    <div className="overview-color">Màu {model.colorName}</div>
                    <div className="badge-type">Tự thiết kế</div>
                </div>
            </section>

            <section className="product_info">
                <h6>THÔNG TIN SẢN PHẨM</h6>
                <div className="product_info-body">
                    <div className="info-row">
                        <label htmlFor="" className="required">
                            Tên sản phẩm
                        </label>
                        <div className="info-input">
                            {/* <div className="template">
                                <div className="list-tag">
                                    <div className="tag">Áo thun</div>
                                    <div className="tag">Áo kid</div>
                                    <div className="tag">Bình nước 1</div>
                                    <div className="tag">Bình nước 2</div>
                                </div>
                                <div className="open-template">
                                    <div className="icon">
                                        <OpenTemplateIcon />
                                    </div>
                                </div>
                            </div> */}
                            <div className="input-area">
                                <input
                                    type="text"
                                    value={model.name}
                                    onChange={(e) => {
                                        let length = e.target.value.length;
                                        if (length > 120) return;
                                        setModel({ ...model, name: e.target.value });
                                    }}
                                />
                                <span className="suffix">{model && model.name ? model.name.length : 0}/120</span>
                            </div>
                        </div>
                    </div>
                    <div className="info-row">
                        <label htmlFor="" className="required">
                            Thương hiệu
                        </label>
                        <div className="info-input">
                            <div className="input-area">
                                <input type="text" value={`Ranus`} readOnly className="disabled" />
                            </div>
                        </div>
                    </div>
                    <div className="info-row">
                        <label htmlFor="" className="required">
                            Mô tả sản phẩm
                        </label>
                        <div className="info-input">
                            {/* <div className="template">
                                <div className="list-tag">
                                    <div className="tag">Áo thun</div>
                                    <div className="tag">Áo kid</div>
                                    <div className="tag">Bình nước 1</div>
                                    <div className="tag">Bình nước 2</div>
                                </div>
                                <div className="open-template">
                                    <div className="icon">
                                        <OpenTemplateIcon />
                                    </div>
                                </div>
                            </div> */}
                            <div className="input-area">
                                <textarea
                                    rows={5}
                                    type="text"
                                    value={model.content}
                                    onChange={(e) => {
                                        setModel({ ...model, content: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="info-row">
                        <div className="label-area">
                            <label htmlFor="" className="required">
                                Kích thước
                            </label>
                            <div className="sub-instruction">Dài (cm) x Rộng (cm) x Cao (cm)</div>
                        </div>

                        <div className="info-input package">
                            <div className="info-length">
                                <input
                                    value={model.length}
                                    onChange={(e) => {
                                        setModel({ ...model, length: e.target.value });
                                    }}
                                    placeholder="Dài"
                                    type="number"
                                />
                            </div>
                            <div className="info-width">
                                <input
                                    value={model.width}
                                    onChange={(e) => {
                                        setModel({ ...model, width: e.target.value });
                                    }}
                                    placeholder="Rộng"
                                    type="number"
                                />
                            </div>
                            <div className="info-height">
                                <input
                                    value={model.height}
                                    onChange={(e) => {
                                        setModel({ ...model, height: e.target.value });
                                    }}
                                    placeholder="Cao"
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="info-row">
                        <div className="label-area">
                            <label htmlFor="" className="required">
                                Cân nặng
                            </label>
                            <div className="sub-instruction">Gr</div>
                        </div>

                        <div className="info-input weight">
                            <div className="info-weight">
                                <input
                                    value={model.weight}
                                    onChange={(e) => {
                                        setModel({ ...model, weight: e.target.value });
                                    }}
                                    type="number"
                                    placeholder="Cân nặng"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="push_product">
                <section className="push_shop">
                    <h6>Chọn shop muốn đẩy</h6>
                    {model.multiPlatformModels &&
                        model.multiPlatformModels.map((item, index) => {
                            return (
                                <div className="shop-platform" key={index}>
                                    <div className="heading">
                                        <div className="icon">{getImage(item.platform)}</div>
                                    </div>
                                    <div className="list-shop">
                                        {item.shopOptions &&
                                            item.shopOptions.length > 0 &&
                                            item.shopOptions.map((shop, idx) => {
                                                return (
                                                    <div className={`shop-item ${shop.isActive ? "active" : ""}`} key={idx}>
                                                        <input
                                                            type="checkbox"
                                                            checked={shop.isChecked}
                                                            onChange={(e) => {
                                                                shop.isChecked = e.target.checked;
                                                                setModel({ ...model });
                                                            }}
                                                            className="cursor-pointer"
                                                        />
                                                        <div
                                                            className="shop-name"
                                                            onClick={(e) => {
                                                                let md = { ...model };
                                                                let platforms = md.multiPlatformModels;
                                                                for (var pl of platforms) {
                                                                    pl.shopOptions.forEach((x) => {
                                                                        x.isActive = false;
                                                                    });
                                                                }
                                                                let platform = platforms[index];
                                                                platform.isActive = true;
                                                                let shopOptions = platform.shopOptions;
                                                                let current = shopOptions[idx];
                                                                current.isActive = true;
                                                                setModel({ ...model });
                                                            }}
                                                        >
                                                            {shop.label}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            );
                        })}
                </section>

                {checkedShop && (
                    <section className="push_detail">
                        <h6>Tùy chọn render hình và giá</h6>
                        <div className="push_detail-body">
                            <div className="push_detail-header">
                                {checkedPlatform && <div className="icon">{getImage(checkedPlatform.platform)}</div>}
                                <div className="detail-shop-name">{checkedShop.label}</div>
                            </div>
                            <div className="push_detail-cat">
                                <div className="cat-left">
                                    <label htmlFor="" className="required">
                                        Ngành hàng
                                    </label>
                                    <TFUSelect
                                        placeholder="Chọn ngành hàng"
                                        options={checkedShop.listCat}
                                        value={checkedShop.opfCategoryId}
                                        onChanged={(newValue) => {
                                            let md = { ...model };
                                            let platforms = md.multiPlatformModels;
                                            let platform = platforms.find((x) => x.platform == checkedShop.platform);
                                            let shopOptions = platform.shopOptions;
                                            let current = shopOptions.find((x) => x.value == checkedShop.value);
                                            current.opfCategoryId = newValue;
                                            setModel({ ...model });
                                        }}
                                    />
                                </div>
                                <div className="cat-right">
                                    <label htmlFor="" className="required">
                                        Danh mục
                                    </label>
                                    <TFUSelect
                                        placeholder="Chọn danh mục"
                                        options={checkedShop.listShopCat}
                                        value={checkedShop.shopCatId}
                                        onChanged={(newValue) => {
                                            let md = { ...model };
                                            let platforms = md.multiPlatformModels;
                                            let platform = platforms.find((x) => x.platform == checkedShop.platform);
                                            let shopOptions = platform.shopOptions;
                                            let current = shopOptions.find((x) => x.value == checkedShop.value);
                                            current.shopCatId = newValue;
                                            setModel({ ...model });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="push_detail-photoshop">
                                <div className="list-file">
                                    {checkedPlatform &&
                                        checkedPlatform.photoshopFiles &&
                                        checkedPlatform.photoshopFiles.length > 0 &&
                                        checkedPlatform.photoshopFiles.map((item, index) => {
                                            return (
                                                <div
                                                    className={`file-item ${item.id == checkedShop.photoshopId ? "active" : ""}`}
                                                    key={index}
                                                    onClick={async (e) => {
                                                        let md = { ...model };
                                                        let platforms = md.multiPlatformModels;
                                                        let platform = platforms.find((x) => x.platform == checkedShop.platform);
                                                        let shopOptions = platform.shopOptions;
                                                        let current = shopOptions.find((x) => x.value == checkedShop.value);
                                                        current.photoshopId = item.id;

                                                        //lấy ra thông tin sản phẩm theo photoshopId
                                                        let pushModel = {
                                                            designId: designId,
                                                            photoshopId: item.id,
                                                            platform: checkedShop.platform,
                                                            shopId: checkedShop.value,
                                                        };
                                                        let psProducts = await _getPhotoshopProduct(pushModel);
                                                        let first = psProducts && psProducts.length > 0 ? psProducts[0] : null;
                                                        if (first) first.avatarChecked = true;
                                                        current.photoshopProducts = psProducts;
                                                        setModel({ ...model });
                                                    }}
                                                >
                                                    <div className="file-avatar">
                                                        <img src={item.thumbnail} />
                                                    </div>
                                                    <div className="file-name">{item.name}</div>
                                                    {item.id == checkedShop.photoShopId && (
                                                        <div className="active-icon">
                                                            <img src={ActiveMark} alt="" />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="push_detail-printside">
                                <label htmlFor="" className="required">
                                    Hiển thị mặc định
                                </label>
                                <div className="list-print-side">
                                    <div
                                        className="print-side-item"
                                        onClick={(e) => {
                                            let md = { ...model };
                                            let platforms = md.multiPlatformModels;
                                            let platform = platforms.find((x) => x.platform == checkedShop.platform);
                                            let shopOptions = platform.shopOptions;
                                            let current = shopOptions.find((x) => x.value == checkedShop.value);
                                            current.artworkType = Artwork_Type.Front;
                                            setModel({ ...model });
                                        }}
                                    >
                                        <input type="checkbox" checked={checkedShop.artworkType == Artwork_Type.Front} />
                                        <div className="print-side-name">Mặt trước</div>
                                    </div>
                                    <div
                                        className="print-side-item"
                                        onClick={(e) => {
                                            let md = { ...model };
                                            let platforms = md.multiPlatformModels;
                                            let platform = platforms.find((x) => x.platform == checkedShop.platform);
                                            let shopOptions = platform.shopOptions;
                                            let current = shopOptions.find((x) => x.value == checkedShop.value);
                                            current.artworkType = Artwork_Type.Back;
                                            setModel({ ...model });
                                        }}
                                    >
                                        <input type="checkbox" checked={checkedShop.artworkType == Artwork_Type.Back} />
                                        <div className="print-side-name">Mặt sau</div>
                                    </div>
                                </div>
                            </div>

                            <div className="push_detail-select-product">
                                <label htmlFor="" className="required">
                                    Sản phẩm hiển thị (Được chọn nhiều)
                                </label>
                                <div className="list-product">
                                    {checkedShop.photoshopProducts &&
                                        checkedShop.photoshopProducts.length > 0 &&
                                        checkedShop.photoshopProducts.map((item, index) => {
                                            return (
                                                <div
                                                    className="product-select-item"
                                                    key={index}
                                                    onClick={(e) => {
                                                        let md = { ...model };
                                                        let platforms = md.multiPlatformModels;
                                                        let platform = platforms.find((x) => x.platform == checkedShop.platform);
                                                        let shopOptions = platform.shopOptions;
                                                        let current = shopOptions.find((x) => x.value == checkedShop.value);
                                                        let psProducts = current.photoshopProducts;
                                                        let currentProduct = psProducts[index];
                                                        currentProduct.checked = !currentProduct.checked;
                                                        setModel({ ...model });
                                                    }}
                                                >
                                                    <input type="checkbox" checked={item.checked} />
                                                    <div className="product-select-name">{item.appProductName}</div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            {/* 
                            <div className="push_detail-select-product">
                                <label htmlFor="" className="required">
                                    Dòng ốp muốn đẩy
                                </label>
                                <div className="list-product">
                                    <div className="product-select-item">
                                        <input type="checkbox" />
                                        <div className="product-select-name">Iphone</div>
                                    </div>
                                </div>
                            </div>
                            <div className="push_detail-select-product">
                                <label htmlFor="" className="required">
                                    Sản phẩm muốn đẩy
                                </label>
                                <div className="text-danger">Quá 100 biến thể, không đẩy được</div>
                                <div className="list-product">
                                    <div className="product-select-item">
                                        <input type="checkbox" />
                                        <div className="product-select-name">Áo thun</div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="push_detail-price-stock">
                                <label htmlFor="" className="required">
                                    Chọn Avatar, Giá và Tồn kho theo dòng sản phẩm
                                </label>
                                <div className="section-table">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Avatar</th>
                                                <th colSpan={2}>Dòng sản phẩm</th>
                                                <th>Size</th>
                                                <th>Scale</th>
                                                <th>Giá sản phẩm</th>
                                                <th>Chi phí in</th>
                                                <th>Giá gạch (Sell Price)</th>
                                                <th>Giảm giá</th>
                                                <th>Giảm giá theo SHOP</th>
                                                <th>Giá bán</th>
                                                <th>Số lượng</th>
                                                <th>Mã SKU</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkedShop &&
                                                checkedShop.photoshopProducts &&
                                                checkedShop.photoshopProducts.length > 0 &&
                                                checkedShop.photoshopProducts.map((ele, idx) => {
                                                    if (!ele.pushingPhotoshopVariants || !ele.checked) return;
                                                    var rowSpan = ele.pushingPhotoshopVariants.length;

                                                    return (
                                                        <React.Fragment>
                                                            {ele.pushingPhotoshopVariants &&
                                                                ele.pushingPhotoshopVariants.map((variant, i) => {
                                                                    var sellPrice = variant.sellPrice + variant.printCost;
                                                                    var salePrice = sellPrice - variant.discountBrandVoucher - variant.lowCostDiscount;
                                                                    return (
                                                                        <tr key={i}>
                                                                            {i === 0 && (
                                                                                <React.Fragment>
                                                                                    <td rowSpan={rowSpan}>
                                                                                        <div className="check-box">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={ele.avatarChecked}
                                                                                                onChange={(e) => {
                                                                                                    let md = { ...model };
                                                                                                    let platforms = md.multiPlatformModels;
                                                                                                    let platform = platforms.find((x) => x.platform == checkedShop.platform);
                                                                                                    let shopOptions = platform.shopOptions;
                                                                                                    let current = shopOptions.find((x) => x.value == checkedShop.value);
                                                                                                    let psProducts = current.photoshopProducts;
                                                                                                    psProducts.forEach((x) => (x.avatarChecked = false));
                                                                                                    let product = psProducts[idx];
                                                                                                    product.avatarChecked = e.target.checked;
                                                                                                    setModel({ ...model });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td rowSpan={rowSpan}>
                                                                                        <img src={ele.appProductAvatar} />
                                                                                    </td>
                                                                                    <td rowSpan={rowSpan}>{ele.appProductName}</td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            <td>{variant.sizeValue}</td>
                                                                            <td>{variant.scale}</td>
                                                                            <td>{Common.formatCurrency(variant.sellPrice)}</td>
                                                                            <td>{Common.formatCurrency(variant.printCost)}</td>
                                                                            <td>{Common.formatCurrency(sellPrice)}</td>
                                                                            <td>{Common.formatCurrency(variant.discountBrandVoucher)}</td>
                                                                            <td>{Common.formatCurrency(variant.lowCostDiscount)}</td>
                                                                            <td>{Common.formatCurrency(salePrice)}</td>
                                                                            <td>{variant.quantity}</td>
                                                                            <td>{variant.sku}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <section className="btn-bottom">
                    <div className="list-button">
                        <button className="btn btn-primary">Trở về</button>
                        <button
                            className="btn btn-primary"
                            onClick={(e) => {
                                _createUpdatePushProduct();
                            }}
                        >
                            Đẩy sản phẩm
                        </button>
                    </div>
                </section>
            </section>
        </Wrapper>
    );
}

export default PushingMultiDetail;

const Wrapper = styled.div`
    .list-file {
        overflow-x: auto;
    }

    .avatar {
        width: 120px;
        height: 120px;
    }

    .avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    section.product_overview {
        width: 20vw;
        background: white;
        display: flex;
        margin: 6px;
        padding: 6px;
    }

    .overview-section {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
    }

    .overview-highlight {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }

    .badge-type {
        margin-top: auto;
        width: fit-content;
        padding: 4px 10px;
        border-radius: 40px;
        background: #f20e45;
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .overview-title {
        color: #444;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        margin-top: 6px;
    }

    .overview-color {
        color: #444;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .product_info-body {
        padding: 6px;
        background: white;
    }

    section.product_info {
        margin: 6px;
    }

    .info-row {
        display: flex;
        width: 50%;
        margin: 24px 0;
    }

    .info-row label {
        width: 150px;
        flex-shrink: 0;
    }

    .info-input {
        width: 100%;
    }

    .template {
        display: flex;
    }

    .open-template {
        display: flex;
        margin-left: auto;
    }

    .list-tag {
        display: flex;
        align-items: center;
    }

    .tag {
        color: #64c5b1;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-right: 12px;
    }

    .input-area {
        border: 1px solid #d9d9d9;
        background: #fff;
        display: flex;
        padding: 6px 12px;
        margin: 6px 0;
    }

    .input-area input[type="text"] {
        border: none;
        flex: 1;
    }

    .info-input.select {
    }

    .info-input.select .select-options {
        width: 30%;
    }

    .input-area textarea {
        width: 100%;
        height: 100%;
        border: none;
    }

    .label-area {
        width: 150px;
        flex-shrink: 0;
    }

    .label-area label {
        width: 100%;
        margin-bottom: 0px;
    }

    .sub-instruction {
        color: #a4a4a4;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }

    .info-input.package {
        display: flex;
    }

    .info-length {
        margin-right: 8px;
        display: flex;
    }

    .info-width {
        margin-right: 8px;
        display: flex;
    }

    .info-height {
        display: flex;
    }

    .info-length input {
        padding: 6px 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .info-width input {
        padding: 6px 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .info-height input {
        padding: 6px 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    .info-input.weight {
        display: flex;
    }

    .info-weight {
        display: flex;
    }

    .info-weight input {
        border: 1px solid #d9d9d9;
        background: #fff;
    }

    section.push_product {
        display: flex;
        flex-wrap: wrap;
    }

    section.push_shop {
        width: 20%;
    }

    section.push_detail {
        width: calc(80% - 16px);
        margin-left: 16px;
    }

    .shop-platform {
        background: white;
        padding: 6px 0;
    }

    .heading {
        padding: 0px 16px;
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
        align-items: center;
    }

    .shop-item {
        display: flex;
        align-items: center;
        padding: 8px 21px;
        margin: 6px 0;
    }

    .shop-name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 12px;
    }

    .shop-item.active {
        background: #eafaf7;
    }

    .shop-name .badge {
        border-radius: 40px;
        background: var(--Cam-Thng-hiu, #ff9027);
        color: #fff;
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 181.818% */
        margin-left: 8px;
    }

    .push_detail-body {
        background: white;
        padding: 13px 18px;
    }

    .push_detail-header {
        display: flex;
        align-items: center;
        margin: 14px 0;
    }

    .detail-shop-name {
        margin-left: 21px;
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .push_detail-cat {
        margin: 14px 0;
        display: flex;
    }

    .cat-left {
        width: 45%;
        margin-right: 10%;
    }

    .cat-right {
        width: 45%;
    }

    .list-file {
        display: flex;
    }

    .file-avatar {
        width: 110px;
        height: 110px;
    }

    .file-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .file-item {
        position: relative;
        margin: 0 6px;
        width: 110px;
    }

    .active-icon {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
    }

    .file-item.active {
    }

    .file-item.active .file-avatar {
        border: 1px solid #64c5b1;
        background: rgba(100, 197, 177, 0.5);
        padding: 4px;
    }

    .list-file {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 6px;
    }

    .file-name {
        color: #676767;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 7px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .push_detail-photoshop {
        margin: 14px 0;
    }

    .push_detail-printside {
        margin: 14px 0;
        display: flex;
        align-items: center;
    }

    .list-print-side {
        display: flex;
        align-items: center;
        margin-left: 12px;
    }

    .print-side-item {
        display: flex;
        align-items: center;
        margin: 0 6px;
    }

    .print-side-name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 4px;
    }

    .push_detail-printside label {
        margin: 0;
    }

    .push_detail-select-product {
        margin: 14px 0;
    }

    .list-product {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 15px 12px;
        display: flex;
        flex-wrap: wrap;
    }

    .product-select-item {
        display: flex;
        align-items: center;
        margin: 12px 24px;
    }

    .product-select-name {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 10px;
    }

    .push_detail-price-stock {
        margin: 14px 0;
    }

    td img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    tr td {
        vertical-align: middle !important;
        text-align: center;
    }

    tr th {
        text-align: center;
        vertical-align: middle !important;
    }

    section.btn-bottom {
        margin: 12px 0 0 auto;
    }

    .list-button .btn {
        margin: 0 6px;
    }
`;

const OpenTemplateIcon = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1H7C8.06087 1 9.07828 1.42143 9.82843 2.17157C10.5786 2.92172 11 3.93913 11 5V19C11 18.2044 10.6839 17.4413 10.1213 16.8787C9.55871 16.3161 8.79565 16 8 16H1V1Z"
                stroke="#64C5B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21 1H15C13.9391 1 12.9217 1.42143 12.1716 2.17157C11.4214 2.92172 11 3.93913 11 5V19C11 18.2044 11.3161 17.4413 11.8787 16.8787C12.4413 16.3161 13.2044 16 14 16H21V1Z"
                stroke="#64C5B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
