import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import Common from "../based/Common";
import CommonTable from "../based/CommonTable";
import { ARTWORK_TYPE, NOTIFY, PAYMENT_METHOD, PLATFORM, PLATFORM_NAME, STATUS_TRANSACTION, STATUS_TRANSACTION_NAME } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import { Notify } from "../based/Notify";
import TFUDatetime from "../based/inputs/TFUDatetime";
import BaseModal from "../based/refactor/BaseModal";
import TFUSelect from "../based/refactor/TFUSelect";
import OrderServices from "../based/services/OrderServices";
import OrderBookingModal from "../operator-order-packing/OrderBookingModal";
import NoteModal from "./NoteModal";
import SMSManualModal from "./SMSManualModal";

const OrderGiftProductDTO = {
    orderId: 0,
    giftId: 0,
    quantity: 0,
    giftName: "",
    giftUrl: "",
};

const OrderProductManage = {
    orderId: 0,
    productName: "",
    productSku: "",
    agencyProductId: 0,
    quantity: 0,
    price: 0,
    finallyPrice: 0,
    discount: 0,
    attributeValues: "",
    printType: "",
    printSide: "",
    appProductName: "",
    productAvatar: "",
    productFrontUrl: "",
    productBackUrl: "",
};

const OrderProductCustomizeManage = {
    id: 0,
    orderId: 0,
    appProductVariantId: 0,
    designId: 0,
    price: 0,
    salePrice: 0,
    quantity: 0,
    discount: 0,
    finallyPrice: 0,
    printType: "",
    designOrderId: 0,
    referenceCode: "",
    appProductName: "",
    color: "",
    size: "",
    productImageFrontUrl: "",
    productImageBackUrl: "",
};

const OrderGiftManage = {
    orderId: 0,
    productVariantId: 0,
    isFlexicombo: false,
    quantity: 0,
    productName: "",
    productAvatar: "",
    productSellPrice: 0,
};

const OrderManageTotalModel = {
    id: 0,
    orderNumber: "",
    createdDate: new Date(),
    createdBy: "",
    thirdPartyId: 0,
    shippingAddressId: 0,
    billingAddressId: 0,
    shippingCompanyId: 0,
    status: "",
    remarks: "",
    paymentMethod: "",
    paymentMethodName: "",
    totalPrice: 0,
    shippingFee: 0,
    packageCost: 0,
    totalDiscount: 0,
    finallyPrice: 0,
    thirdPartyName: "",
    transferPartial: 0,
    email: "",
    isSystem: false,
    customerName: "",
    phoneNumber: "",
    shippingAddressName: "",
    shippingCompanyName: "",
    orderProducts: [OrderProductManage],
    orderCustomizeProducts: [OrderProductCustomizeManage],
    orderGiftProducts: [OrderGiftProductDTO],
    orderGifts: [OrderGiftManage],
    rowSpan: 1,
    orderCancelStatus: 0,
    orderCancelDetail: "",
    orderCancelStatusName: "",
};

const CustomerType = {
    All: 0,
    Customer: 1,
    System: 2,
    VIP: 3,
    Boom: 4,
};

const SectionType = {
    All: 0,
    Shop: 1, //cửa hàng
    Brand: 2, //thương hiệu
    Customize: 3, //tự thiết kế
};

const CustomerTypeOptions = [
    { value: CustomerType.All, label: "Tất cả (KH)" },
    { value: CustomerType.Customer, label: "Khách hàng" },
    { value: CustomerType.System, label: "Hệ thống" },
    { value: CustomerType.VIP, label: "VIP" },
    { value: CustomerType.Boom, label: "BOOM" },
];

const PaymentMethodOptions = [
    { value: 0, label: "Tất cả (PTTT)" },
    { value: PAYMENT_METHOD.COD, label: "COD" },
    { value: PAYMENT_METHOD.COD_TO_TRANSFER, label: "COD Một phần" },
    { value: PAYMENT_METHOD.MOMO_WALLET, label: "Ví Momo" },
];

const SectionTypeOptions = [
    { value: SectionType.All, label: "Tất cả (SHOP)" },
    { value: SectionType.Shop, label: "Cửa hàng" },
    { value: SectionType.Brand, label: "Thương hiệu" },
    { value: SectionType.Customize, label: "Tự thiết kế" },
];

const PLATFORM_TAB_OPTIONS = [
    { value: PLATFORM.Internal, label: PLATFORM_NAME.Internal },
    { value: PLATFORM.Lazada, label: PLATFORM_NAME.Lazada },
    { value: PLATFORM.Shopee, label: PLATFORM_NAME.Shopee },
];

const ORDER_STATUS_OPTIONS = [
    {
        value: 0,
        label: "Tất cả",
    },
    {
        value: STATUS_TRANSACTION.UNPAID_FROM_CUSTOMER, //đang xử lý
        label: STATUS_TRANSACTION_NAME.UNPAID_FROM_CUSTOMER,
    },
    {
        value: STATUS_TRANSACTION.PROCESSING, //đang xử lý
        label: STATUS_TRANSACTION_NAME.PROCESSING,
    },
    {
        value: STATUS_TRANSACTION.PRODUCTION, //đang sản xuất
        label: STATUS_TRANSACTION_NAME.PRODUCTION,
    },
    {
        value: STATUS_TRANSACTION.PACKING, //đang đóng gói
        label: STATUS_TRANSACTION_NAME.PACKING,
    },
    {
        value: STATUS_TRANSACTION.READYTOSHIP, //sẵn sàng giao
        label: STATUS_TRANSACTION_NAME.READYTOSHIP,
    },
    {
        value: STATUS_TRANSACTION.SHIPPING, //đang vận chuyển
        label: STATUS_TRANSACTION_NAME.SHIPPING,
    },
    {
        value: STATUS_TRANSACTION.FAIL_DELIVERY_TO_CUSTOMER, //giao thất bại
        label: STATUS_TRANSACTION_NAME.FAIL_DELIVERY_TO_CUSTOMER,
    },
    {
        value: STATUS_TRANSACTION.RETURNED_TO_SENDER, //hoàn trả về cho người gửi
        label: STATUS_TRANSACTION_NAME.RETURNED_TO_SENDER,
    },
    {
        value: STATUS_TRANSACTION.CANCELED, //hủy
        label: STATUS_TRANSACTION_NAME.CANCELED,
    },
    {
        value: STATUS_TRANSACTION.SUCCEED, //giao thành công
        label: STATUS_TRANSACTION_NAME.SUCCEED,
    },
    {
        value: STATUS_TRANSACTION.ERRORED, //lỗi
        label: STATUS_TRANSACTION_NAME.ERRORED,
    },
];

const initPaging = {
    pageNumber: 1,
    pageSize: 50,
    keyword: "",
    platform: PLATFORM.Internal,
    shopName: "",
    shopId: "",
    status: STATUS_TRANSACTION.PROCESSING,
    customerType: 0,
    sectionType: 0,
    paymentMethod: 0,
    fromDate: new Date(new Date().setMonth(new Date().getMonth() - 12)),
    toDate: new Date(new Date().setDate(new Date().getDate() + 1)),
};

function ManageOrderTotal(props) {
    const [shopOptions, setShopOptions] = useState([]);
    const [paging, setPaging] = useState({ ...initPaging });
    const [datas, setDatas] = useState([OrderManageTotalModel]);
    const [isLoading, setIsLoading] = useState(false);
    const [showBooking, setShowBooking] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [listImage, setListImage] = useState([]);
    const [listCountModel, setListCountModel] = useState([]);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectOrderId, setSelectOrderId] = useState(0);
    const [showCode, setShowCode] = useState(false);

    useEffect(() => {
        getOrders(paging);
        getCountOrder(paging.platform);
    }, []);

    async function getOrders(paging) {
        setIsLoading(true);
        let [err, data] = await OrderServices.GetOrdersPage(paging);
        if (!err && data) {
            let lstObjects = data.listObjects;
            let page = data.paging;
            setDatas(lstObjects);
            setPaging(page);
        } else {
            setDatas([]);
            setPaging(paging);
        }
        setIsLoading(false);
    }

    async function markIsConnect(orderId, isConnect) {
        setIsLoading(true);
        const [err, data] = await OrderServices.MarkIsConnect(orderId, isConnect);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật trạng thái kết nối thành công");
            let lstOrder = [...datas];
            let orderItem = lstOrder.find((x) => x.id == orderId);
            if (orderItem) orderItem.isConnect = isConnect;
            setDatas(lstOrder);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", err && err.message ? err.message : "Có lỗi xảy ra trong quá trình cập nhật trạng thái kết nối");
        }

        setIsLoading(false);
    }

    async function markIsBoom(orderId) {
        const [err, data] = await OrderServices.MarkIsBoom(orderId);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Đánh dấu đơn hàng BOOM thành công");
            let lstOrder = [...datas];
            let orderItem = lstOrder.find((x) => x.id == orderId);
            if (orderItem) orderItem.isBoom = true;
            setDatas(lstOrder);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", err && err.message ? err.message : "Có lỗi xảy ra trong quá trình đánh dấu đơn hàng BOOM");
        }
    }

    async function removeIsBoom(orderId) {
        const [err, data] = await OrderServices.RemoveIsBoom(orderId);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Xóa đơn hàng BOOM thành công");
            let lstOrder = [...datas];
            let orderItem = lstOrder.find((x) => x.id == orderId);
            if (orderItem) orderItem.isBoom = false;
            setDatas(lstOrder);
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", err && err.message ? err.message : "Có lỗi xảy ra trong quá trình đánh dấu đơn hàng BOOM");
        }
    }

    async function getCountOrder(platform) {
        const [err, data] = await OrderServices.GetOrderPageCountModel(platform);
        if (!err && data) {
            setListCountModel(data);
        } else {
            setListCountModel([]);
        }
    }

    function _renderPlatformTab() {
        return PLATFORM_TAB_OPTIONS.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a
                        className={paging.platform == item.value ? "nav-link active" : "nav-link btn-light"}
                        onClick={() => {
                            getOrders({
                                ...paging,
                                platform: +item.value,
                            });
                        }}
                    >
                        <span>{item.label}</span>
                    </a>
                </li>
            );
        });
    }

    function _renderStatusTab() {
        return ORDER_STATUS_OPTIONS.map((item, index) => {
            let countModel = listCountModel.find((x) => x.status == item.value);
            let count = countModel ? countModel.count : 0;
            return (
                <li key={index} className="nav-item position-relative">
                    <a
                        className={`nav-link d-flex align-items-center ${paging.status == item.value ? "active" : "btn-light"}`}
                        onClick={() => {
                            getOrders({
                                ...paging,
                                status: +item.value,
                            });
                        }}
                    >
                        <span>{item.label}</span>
                        {count > 0 && <span className="count-number">{count}</span>}
                    </a>
                </li>
            );
        });
    }

    function _renderShops() {
        return (
            <ul className="nav nav-tabs tabs-bordered">
                {shopOptions.map((shop, index) => {
                    return (
                        <li key={index} className="nav-item">
                            <a
                                className={paging.shopId == shop.value ? "nav-link active" : "nav-link btn-light"}
                                onClick={() => {
                                    getOrders({
                                        ...paging,
                                        shopId: shop.value,
                                        shopName: shop.label,
                                    });
                                }}
                            >
                                <span>{shop.label}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    function _renderAction() {
        return (
            <div className="action">
                <div className="action-left">
                    <div className="action-search">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Tìm kiếm"
                            onChange={(e) => {
                                setPaging({
                                    ...paging,
                                    keyword: e.target.value,
                                });
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    getOrders(paging);
                                }
                            }}
                            value={paging.keyword}
                        />
                        <div
                            className="search-icon"
                            onClick={() => {
                                getOrders(paging);
                            }}
                        >
                            <div className="icon">
                                <SearchSVG />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-left-button">{_renderProcessOrderActionSwitch(paging.platform)}</div>
                <div className="action-right">
                    <div className="customer-type">
                        <TFUSelect
                            placeholder="Đơn khách hàng"
                            options={CustomerTypeOptions}
                            value={paging.customerType}
                            name="customer-type"
                            onChanged={(value) => {
                                getOrders({
                                    ...paging,
                                    customerType: +value,
                                });
                            }}
                        />
                    </div>
                    <div className="section-type">
                        <TFUSelect
                            placeholder="Tự thiết kế"
                            options={SectionTypeOptions}
                            value={paging.sectionType}
                            name="section-type"
                            onChanged={(value) => {
                                getOrders({
                                    ...paging,
                                    sectionType: +value,
                                });
                            }}
                        />
                    </div>
                    <div className="section-type">
                        <TFUSelect
                            placeholder="Phương thức thanh toán"
                            options={PaymentMethodOptions}
                            value={paging.paymentMethod}
                            name="section-type"
                            onChanged={(value) => {
                                getOrders({
                                    ...paging,
                                    paymentMethod: +value,
                                });
                            }}
                        />
                    </div>
                    <div className="filter-date">
                        <TFUDatetime
                            maxDate={new Date(paging.toDate)}
                            value={new Date(paging.fromDate)}
                            onChanged={(date, name) => {
                                getOrders({
                                    ...paging,
                                    fromDate: date,
                                });
                            }}
                            name="from-date"
                            className="form-control from-date"
                            showDateOnly
                        />
                        <TFUDatetime
                            minDate={new Date(paging.fromDate)}
                            value={new Date(paging.toDate)}
                            onChanged={(date, name) => {
                                getOrders({
                                    ...paging,
                                    toDate: date,
                                });
                            }}
                            name="to-date"
                            className="form-control to-date"
                            showDateOnly
                        />
                    </div>
                </div>
            </div>
        );
    }

    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="text-center"></th>
                    <th className="text-center">Khách hàng</th>
                    <th className="text-center">Sản phẩm</th>
                    <th className="text-center">Ảnh</th>
                    <th className="text-center">Loại sản phẩm</th>
                    <th className="text-center">Giá</th>
                    <th className="text-center">Số lượng</th>
                    <th className="text-center">Giảm giá</th>
                    <th className="text-center">Phương thức thanh toán</th>
                    <th className="text-center">ĐVVC</th>
                    <th className="text-center">Trạng thái</th>
                </tr>
            </thead>
        );
    }

    function _renderBody() {
        if (datas && datas.length > 0) return <tbody>{_renderContentBody()}</tbody>;
        else
            return (
                <tbody>
                    <tr>
                        <td colSpan="999" className="vertical-align-middle text-center">
                            Không có dữ liệu
                        </td>
                    </tr>
                </tbody>
            );
    }

    function _renderContentBody() {
        let content = [];
        datas.map((order, index) => {
            let firstColumn = [];
            let lastColumn = [];

            //checkbox
            firstColumn.push(
                <td
                    className="text-center vertical-middle"
                    rowSpan={order.rowSpan}
                    style={{
                        width: "50px",
                    }}
                >
                    <input
                        type="checkbox"
                        checked={order.isChecked}
                        onChange={(e) => {
                            let isChecked = e.target.checked;
                            let newDatas = [...datas];
                            newDatas[index].isChecked = isChecked;
                            setDatas(newDatas);
                        }}
                    />
                </td>
            );
            //khách hàng
            firstColumn.push(
                <td
                    className={`text-left vertical-middle ${order.isBoom || order.likelyBoomOrder ? "is-boom" : order.isConnect ? "is-connect" : ""}`}
                    style={{
                        maxWidth: "300px",
                    }}
                    rowSpan={order.rowSpan}
                >
                    <div className="customer-header">
                        {order.isSystem ? <div className="type-system">Hệ thống</div> : order.successOrderCount >= 1 ? <div className="type-vip">Khách hàng thân thiết</div> : <div className="type-customer">Khách hàng</div>}
                        <div className="checkbox-connect">
                            <input
                                type="checkbox"
                                checked={order.isConnect}
                                onChange={(e) => {
                                    markIsConnect(order.id, e.target.checked);
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="order-id"
                        onClick={(e) => {
                            window.open(`/order-detail/${order.id}`, "_blank");
                        }}
                    >
                        {order.id}
                        <div className="open-tab">
                            <div className="icon">
                                <OpenTabSVG />
                            </div>
                        </div>
                    </div>
                    <div className="order-customer">
                        Mã đơn hàng: <span>{order.orderNumber}</span>
                    </div>
                    <div className="order-name">{order.customerName}</div>
                    <div className="order-phone-number">
                        <span>{order.phoneNumber} - </span>
                        <span
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                markIsConnect(order.id, e.target.checked);
                                window.open(`https://zalo.me/${order.phoneNumber}`, "_blank");
                            }}
                            className="badge badge-primary mx-2 cursor-pointer"
                        >
                            Nhắn tin Zalo
                        </span>
                    </div>
                    <div className="order-address">{order.shippingAddressName}</div>
                    <div className="order-created-date">{Common.formatDate(new Date(order.createdDate), "fulldate")}</div>
                    <hr />
                    <div className="remarks">
                        <span
                            className="badge badge-custom cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowNoteModal(true);
                                setSelectOrderId(order.id);
                            }}
                        >
                            Ghi chú:
                        </span>
                        {order.remarks}
                    </div>
                    <div className="mark-is-boom">
                        <span className="badge badge-custom cursor-pointer">Đánh dấu đơn hàng BOOM</span>
                        <div className="mark-boom-btn">
                            <input
                                type="checkbox"
                                checked={order.isBoom}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        markIsBoom(order.id);
                                    } else {
                                        removeIsBoom(order.id);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {order.isRefund && (
                        <div className="refund-address">
                            <span className="badge badge-info">Đã hoàn tiền</span>
                        </div>
                    )}
                </td>
            );
            //giảm giá
            lastColumn.push(
                <td className="text-center vertical-middle" rowSpan={order.rowSpan}>
                    <div className="product-discount">-{Common.formatCurrency(order.totalDiscount)} </div>
                </td>
            );
            //phương thức thanh toán
            let contentAlreadyPay = "";
            if (order.paymentMethod == PAYMENT_METHOD.COD || order.paymentMethod == PAYMENT_METHOD.COD_TO_TRANSFER) {
                if (order.transferPartial > 0) {
                    contentAlreadyPay = "Đã thanh toán " + Common.formatCurrency(order.transferPartial);
                } else {
                    contentAlreadyPay = "Chưa thanh toán";
                }
            }
            lastColumn.push(
                <td className="text-center vertical-middle" rowSpan={order.rowSpan}>
                    <div className="product-finnaly-price">{Common.formatCurrency(order.finallyPrice)} </div>
                    <div className="product-payment-method">{order.paymentMethodName}</div>
                    {contentAlreadyPay && <div className="already-pay">{contentAlreadyPay}</div>}
                </td>
            );
            //đơn vị vận chuyển
            lastColumn.push(
                <td className="text-center vertical-middle" rowSpan={order.rowSpan}>
                    <div className="product-shipper">{order.shippingCompanyName}</div>
                    <div
                        className="product-shipper cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (order.shippingCompanyName == "Shopee Express") window.open(`https://spx.vn/track?${order.shippingTrackingNumber}`, "_blank");
                        }}
                    >
                        {order.shippingTrackingNumber}
                    </div>
                </td>
            );
            //trạng thái
            lastColumn.push(
                <td className="text-center vertical-middle" rowSpan={order.rowSpan}>
                    <div className="product-status">{order.statusName}</div>
                    {order.orderCancelStatus > 0 && (
                        <div className="cancel-detail">
                            <span className="badge badge-custom">Lý do hủy đơn:</span>
                            {order.orderCancelStatusName}
                            {order.orderCancelDetail ? ` (${order.orderCancelDetail})` : ""}
                        </div>
                    )}
                </td>
            );

            //danh sách sản phẩm
            let productColumn = [];

            //cửa hàng/thương hiệu
            if (order.orderProducts)
                order.orderProducts.map((product, index) => {
                    let productContent = [];
                    //sản phẩm
                    productContent.push(
                        <td
                            className="text-left vertical-middle"
                            style={{
                                maxWidth: "300px",
                            }}
                        >
                            <div className="product-name">{product.productName}</div>
                            <div className="product-attribute">
                                Màu {product.color}, Size {product.size}, Kiểu in {product.printTypeName}
                            </div>
                        </td>
                    );
                    //ảnh
                    productContent.push(
                        <td
                            className="text-center vertical-middle"
                            onClick={(e) => {
                                setShowImageModal(true);
                                let lstImage = [];
                                if (product.productFrontUrl) lstImage.push({ url: product.productFrontUrl, type: ARTWORK_TYPE.Front });
                                if (product.productBackUrl) lstImage.push({ url: product.productBackUrl, type: ARTWORK_TYPE.Back });
                                setListImage(lstImage);
                            }}
                        >
                            {product.productFrontUrl && (
                                <div className="image-front">
                                    <img src={product.productFrontUrl} />
                                </div>
                            )}
                            {product.productBackUrl && (
                                <div className="image-back">
                                    <img src={product.productBackUrl} />
                                </div>
                            )}
                        </td>
                    );
                    //loại sản phẩm
                    productContent.push(
                        <td className="text-center vertical-middle">
                            <span className="bagde-section-brand">Cửa hàng/Thương hiệu</span>
                        </td>
                    );
                    //giá
                    productContent.push(
                        <td className="text-center vertical-middle">
                            <div className="product-price">{Common.formatCurrency(product.price)}</div>
                        </td>
                    );
                    //số lượng
                    productContent.push(
                        <td className="text-center vertical-middle">
                            <div className="product-quantity">{product.quantity}</div>
                        </td>
                    );
                    productColumn.push(productContent);
                });

            //tự thiết kế
            if (order.orderCustomizeProducts)
                order.orderCustomizeProducts.map((product, index) => {
                    let productCustomizeContent = [];
                    //sản phẩm
                    productCustomizeContent.push(
                        <td className="text-left vertical-middle">
                            <div className="product-name">{product.appProductName}</div>
                            <div className="product-attribute">
                                Màu {product.color}, Size {product.size}, Kiểu in {product.printTypeName}
                            </div>
                        </td>
                    );
                    //ảnh
                    productCustomizeContent.push(
                        <td
                            className="text-center vertical-middle"
                            onClick={(e) => {
                                setShowImageModal(true);
                                let lstImage = [];
                                if (product.productImageFrontUrl) lstImage.push({ url: product.productImageFrontUrl, type: ARTWORK_TYPE.Front });
                                if (product.productImageBackUrl) lstImage.push({ url: product.productImageBackUrl, type: ARTWORK_TYPE.Back });
                                setListImage(lstImage);
                            }}
                        >
                            {product.productImageFrontUrl && (
                                <div className="image-front">
                                    <img src={product.productImageFrontUrl} />
                                </div>
                            )}
                            {product.productImageBackUrl && (
                                <div className="image-back">
                                    <img src={product.productImageBackUrl} />
                                </div>
                            )}
                        </td>
                    );
                    //loai sản phẩm
                    productCustomizeContent.push(
                        <td className="text-center vertical-middle">
                            <span className="bagde-section-type">Tự thiết kế</span>
                        </td>
                    );
                    //giá
                    productCustomizeContent.push(
                        <td className="text-center vertical-middle">
                            <div className="product-price">{Common.formatCurrency(product.price)}</div>
                        </td>
                    );
                    //số lượng
                    productCustomizeContent.push(
                        <td className="text-center vertical-middle">
                            <div className="product-quantity">{product.quantity}</div>
                        </td>
                    );
                    productColumn.push(productCustomizeContent);
                });

            //quà tặng
            if (order.orderGiftProducts)
                order.orderGiftProducts.map((product, index) => {
                    let productGiftContent = [];
                    //sản phẩm
                    productGiftContent.push(
                        <td className="text-left vertical-middle">
                            <div className="product-name">{product.giftName}</div>
                        </td>
                    );
                    //ảnh
                    productGiftContent.push(
                        <td className="text-center vertical-middle">
                            <div className="image-front">
                                <img src={product.giftUrl} />
                            </div>
                        </td>
                    );
                    //loại sản phẩm
                    productGiftContent.push(
                        <td className="text-center vertical-middle">
                            <span className="bagde-section-type">Quà tặng</span>
                        </td>
                    );
                    //giá
                    productGiftContent.push(
                        <td className="text-center vertical-middle">
                            <div className="product-price">0</div>
                        </td>
                    );
                    //số lượng
                    productGiftContent.push(
                        <td className="text-center vertical-middle">
                            <div className="product-quantity">{product.quantity}</div>
                        </td>
                    );
                    productColumn.push(productGiftContent);
                });

            //ghép các phần lại với nhau
            productColumn.map((product, idx) => {
                if (idx == 0) {
                    content.push(
                        <tr id={`${index}_o${idx}`} key={`${index}_o${idx}`}>
                            {firstColumn.map((i) => i)}
                            {product.map((i) => i)}
                            {lastColumn.map((i) => i)}
                        </tr>
                    );
                } else {
                    content.push(
                        <tr id={`${index}_o${idx}`} key={`${index}_o${idx}`}>
                            {product.map((i) => i)}
                        </tr>
                    );
                }
            });
        });

        return content;
    }

    function _renderProcessOrderActionSwitch(platform) {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.isChecked);
            if (dataChecked && dataChecked.length > 0) isEnabled = true;
        }
        switch (platform) {
            case PLATFORM.Lazada:
            case PLATFORM.Shopee:
            case PLATFORM.Tiki:
            case PLATFORM.Sendo:
                return null;
            default:
                return (
                    <React.Fragment>
                        {paging.status == STATUS_TRANSACTION.PACKING && (
                            <button className={isEnabled && dataChecked.length == 1 ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={() => (isEnabled && dataChecked.length == 1 ? _handlePrint() : {})}>
                                <i className="fa fa-print m-r-5" />
                                Chọn đơn vị vận chuyển và In nhãn Ship
                            </button>
                        )}
                        {(paging.status == STATUS_TRANSACTION.READYTOSHIP || paging.status == STATUS_TRANSACTION.SHIPPING) && (
                            <button
                                className={isEnabled && dataChecked.length == 1 ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"}
                                onClick={() => {
                                    if (isEnabled && dataChecked.length == 1) {
                                        let orderId = dataChecked[0].id;
                                        window.open(`/shipping-label/${orderId}`, "_blank");
                                    } else {
                                    }
                                }}
                            >
                                <i className="fa fa-print m-r-5" />
                                In nhãn Ship
                            </button>
                        )}
                        {paging.status == STATUS_TRANSACTION.READYTOSHIP && (
                            <button className={isEnabled ? "btn btn-custom btn-sm mr-2" : "btn btn-custom btn-sm mr-2 disable"} onClick={() => (isEnabled ? _handleChangeOrderStatus(STATUS_TRANSACTION.SHIPPING) : {})}>
                                <i className="fa fa-check m-r-5"></i> Đang giao hàng
                            </button>
                        )}
                        {(paging.status == STATUS_TRANSACTION.PROCESSING || paging.status == STATUS_TRANSACTION.PRODUCTION) && (
                            <button
                                className={isEnabled ? "btn btn-custom btn-sm mr-2" : "btn btn-custom btn-sm mr-2 disable"}
                                onClick={() => {
                                    if (isEnabled) {
                                        ConfirmDialog("Xác nhận", "Bạn có chắc chắn muốn chuyển trạng thái đơn hàng sang đang đóng gói không?", () => {
                                            return new Promise((resolve, reject) => {
                                                _handleChangeOrderStatus(STATUS_TRANSACTION.PACKING).then((res) => {
                                                    resolve({ title: "Thành công", msg: "Đổi thành công" });
                                                });
                                            });
                                        });
                                    }
                                }}
                            >
                                <i className="fa fa-check m-r-5"></i> Đang đóng gói
                            </button>
                        )}
                        {paging.status == STATUS_TRANSACTION.SHIPPING && (
                            <button className={isEnabled ? "btn btn-custom btn-sm mr-2" : "btn btn-custom btn-sm mr-2 disable"} onClick={() => (isEnabled ? _handleChangeOrderStatus(STATUS_TRANSACTION.SUCCEED) : {})}>
                                <i className="fa fa-check m-r-5"></i> Giao hàng thành công
                            </button>
                        )}
                        <button
                            className={"btn btn-custom btn-sm mr-2"}
                            onClick={() => {
                                setShowCode(true);
                            }}
                        >
                            <i className="fa fa-check m-r-5"></i> Xử lý tay SMS
                        </button>
                    </React.Fragment>
                );
        }
    }

    //xử lý thay đổi trạng thái đơn hàng
    async function _handleChangeOrderStatus(status) {
        let dataChecked = null;
        let isEnabled = false;
        if (datas && datas.length > 0) {
            dataChecked = datas.filter((x) => x.isChecked);
            if (dataChecked && dataChecked.length > 0) isEnabled = true;
        }
        if (isEnabled) {
            let ids = [];
            dataChecked.map((order) => ids.push(order.id));
            if (ids && ids.length > 0) {
                setIsLoading(true);
                let [error, data] = await OrderServices.ChangeMultiOrderStatus(ids, status);
                if (!error) {
                    Notify(NOTIFY.SUCCESS, "Thành công", "Cập nhật trạng thái đơn hàng thành công");
                    setIsLoading(false);
                    getOrders(paging);
                } else {
                    setIsLoading(false);
                    Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình cập nhật trạng thái");
                }
            }
        } else Notify(NOTIFY.INFO, "Chú ý", "Yêu cầu chọn đơn hàng trước khi thực hiện");
    }

    //xử lý in nhãn ship
    async function _handlePrint() {
        let dataChecked = null;
        if (datas && datas.length > 0) {
            let datasChecked = datas.filter((x) => x.isChecked);
            if (datasChecked && datasChecked.length == 1) dataChecked = datasChecked[0];
        }
        if (dataChecked && dataChecked.id > 0) {
            if (paging.platform == PLATFORM.Internal) {
                setShowBooking(true);
            } else {
                setIsLoading(true);
                let [error, data] = await OrderServices.PrintShippingLabel(dataChecked.id, 0);
                if (!error && data) {
                    switch (paging.platform) {
                        case PLATFORM.Lazada:
                            window.open(data.url, "_blank", "noopener,noreferrer");
                            break;
                        case PLATFORM.Shopee:
                            if (data.url) {
                                let arr = data.url.split(",");
                                if (arr && arr.length > 0) {
                                    arr.map((url) => window.open(url, "_blank", "noopener,noreferrer"));
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
                }
            }
        }
    }

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Quản lý đơn hàng" />
            <ul className="nav nav-tabs tabs-bordered">{_renderPlatformTab()}</ul>
            <ul className="nav nav-tabs tabs-bordered">{_renderStatusTab()}</ul>
            {shopOptions && shopOptions.length > 0 && _renderShops()}
            <CommonTable datas={datas} paging={paging} renderAction={_renderAction} renderHeader={_renderHeader} renderBody={_renderBody} onFilter={getOrders} />
            {showBooking && (
                <OrderBookingModal
                    isShowModal={showBooking}
                    order={datas && datas.length > 0 && datas.filter((x) => x.isChecked) && datas.filter((x) => x.isChecked).length > 0 ? datas.filter((x) => x.isChecked)[0] : null}
                    onClose={() => setShowBooking(false)}
                    onSuccess={(shippingCode) => {
                        setShowBooking(false);
                    }}
                    orderId={datas && datas.length > 0 && datas.filter((x) => x.isChecked) && datas.filter((x) => x.isChecked).length > 0 ? datas.filter((x) => x.isChecked)[0].id : 0}
                />
            )}

            <BaseModal
                isShow={showImageModal}
                onClose={() => {
                    setShowImageModal(false);
                    setListImage([]);
                }}
                title={"Ảnh sản phẩm"}
                width={`1000px`}
                height={`640px`}
            >
                <div className="mockup__slider">
                    {listImage.map((subImg, i) => (
                        <div key={i} className="image-mockup-slider">
                            <img src={subImg.url} alt="sub-image" />
                            <div className="type-name">{subImg.type == ARTWORK_TYPE.Front ? "Mặt trước" : "Mặt sau"}</div>
                        </div>
                    ))}
                </div>
            </BaseModal>
            <NoteModal
                show={showNoteModal}
                onClose={() => {
                    setShowNoteModal(false);
                    setSelectOrderId(0);
                }}
                orderId={selectOrderId}
                onSave={() => {
                    setShowNoteModal(false);
                    setSelectOrderId(0);
                    getOrders(paging);
                }}
            />
            <SMSManualModal show={showCode} onClose={() => setShowCode(false)} />
        </Wrapper>
    );
}

export default ManageOrderTotal;

const Wrapper = styled.div`
    .action-right {
        flex-wrap: wrap;
    }

    .action-right div {
        margin: 2px;
    }

    .action-left-button {
        align-self: baseline;
    }
    .action-left-button {
        display: flex;
    }

    .customer-header {
        display: flex;
        align-items: center;
    }

    .mark-is-boom {
        display: flex;
        align-items: center;
        margin: 6px 0;
    }

    .mark-boom-btn {
        display: flex;
        margin-left: 5px;
    }

    .is-boom {
        background: #ffe1ea;
    }

    .checkbox-connect {
        margin-left: auto;
    }

    .is-connect {
        background: #ebfff2;
    }
    li.nav-item.position-relative {
        margin-top: 2px;
    }

    span.count-number {
        border-radius: 10px;
        background: #28b259;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 4px;
        margin-left: 4px;
    }

    .type-name {
        color: #444;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        position: relative;
    }

    .image-mockup-slider {
        width: 50%;
        height: auto;
    }

    .image-mockup-slider img {
        width: 100%;
        height: 100%;
    }

    .mockup__slider {
        display: flex;
    }

    .action-left-button {
        margin-left: 10px;
    }

    .action-left-button button {
        height: 100%;
    }

    .type-system {
        display: inline-block;
        background: #f96a74;
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px;
    }
    .type-customer {
        display: inline-block;
        background: #28b259;
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px;
    }
    .type-vip {
        display: inline-block;
        background: #febd6a;
        color: #fff;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px;
    }

    .order-id {
        display: flex;
        color: #13aaff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 4px 0;
        vertical-align: middle;
        align-items: center;
    }

    .open-tab {
        margin-left: 5px;
    }

    .order-customer {
        color: #666f7b;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 4px 0;
    }

    .order-name {
        color: #666f7b;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 4px 0;
    }

    .order-phone-number {
        margin: 4px 0;
    }

    .order-address {
        margin: 4px 0;
    }

    .product-name {
        overflow: hidden;
        color: #666f7b;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .image-front {
        width: 100px;
        height: 100px;
        object-fit: contain;
        display: inline-block;
    }

    .image-back {
        width: 100px;
        height: 100px;
        object-fit: contain;
        display: inline-block;
    }

    .image-front img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .image-back img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .bagde-section-type {
        border-radius: 20px;
        background: #ffe1ea;
        color: #ff004f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
    }

    .bagde-section-brand {
        color: #13aaff;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 20px;
        background: #d0eeff;
    }

    .bagde-section-type span {
    }

    .product-price {
        color: #666f7b;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .product-quantity {
        color: #666f7b;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .product-discount {
        color: #666f7b;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .product-finnaly-price {
        color: #666f7b;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .product-payment-method {
        margin: 6px 0;
    }

    .already-pay {
        color: #f96a74;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .product-shipper {
        color: #666f7b;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .product-status {
        color: #64c5b1;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    table {
        border-collapse: separate;
        border-spacing: 0 20px;
        border: none;
    }

    .action {
        display: flex;
        margin: 20px 10px !important;
    }

    .action-right {
        margin-left: auto;
        display: flex;
    }

    .action-search {
        display: flex;
        width: 400px;
        border: 1px solid #d9d9d9;
        background: #fff;
        height: 36px !important;
    }

    .action-search input.form-control {
        border: none;
        padding: 10px 15px;
        height: auto !important;
    }

    .action-left {
        display: flex;
    }

    .search-icon {
        display: flex;
        border: 1px solid #d9d9d9;
        background: #444;
        justify-content: center;
        align-items: center;
        padding: 10px 35px !important;
    }

    .icon {
        display: flex;
    }

    .filter-date {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .customer-type {
        width: 180px;
        margin: 0 10px !important;
    }

    .section-type {
        width: 180px;
        margin: 0 10px !important;
    }

    .customer-type > input {
        border: none;
    }

    .from-date,
    .to-date {
        margin: 0 10px !important;
        width: auto;
    }
    .order-id {
        cursor: pointer;
    }
`;

const OpenTabSVG = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5 9.75V14.25C13.5 14.6478 13.342 15.0294 13.0607 15.3107C12.7794 15.592 12.3978 15.75 12 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V6C2.25 5.60218 2.40804 5.22064 2.68934 4.93934C2.97064 4.65804 3.35218 4.5 3.75 4.5H8.25"
                stroke="#13AAFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.25 2.25H15.75V6.75" stroke="#13AAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 10.5L15.75 2.25" stroke="#13AAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

const SearchSVG = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.84532" cy="7.84532" r="7.09532" stroke="white" strokeWidth="1.5" />
            <path d="M12.75 13.2393L16.1823 16.6716" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
};
