import React from "react";
import { ProductVariantModel, VariantImageModel, VariantWarningZoneModel } from "../models/BaseProductModel";

export class ProductVariantContextModel {
    constructor() {
        this.editingVariant = new ProductVariantModel();
        this.setEditingVariant = _setEditingVariant;
        this.productVariants = [new ProductVariantModel()];
        this.setProductVariants = _setProductVariants;
        this.attributes = new Map();
        this.setAttributes = () => {};
        this.addRef = (ref) => {};
        this.listWarningZone = [new VariantWarningZoneModel()];
        this.setListWarningZone = () => {};
        this.listVariantImage = [new VariantImageModel()];
        this.setListVariantImage = () => {};
    }
}

/**
 * set product variants
 * @param {ProductVariantModel} productVariants
 */
function _setProductVariants(productVariants) {}

/**
 * set initial selected product variant
 * @param {ProductVariantModel} editingVariant
 */
function _setEditingVariant(editingVariant) {}

export const ProductVariantContext = React.createContext(new ProductVariantContextModel());
