import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import { INITPAGING, NOTIFY } from "../based/Constants";
import PlatformServices from "../based/services/PlatformServices";
import Common from "../based/Common";
import CommonTablePagination from "../based/CommonTablePagination";
import { Notify } from "../based/Notify";
import { ProductPushingStatus } from "./ProductInformation";

export const shopCatProductModel = {
    id: 0,
    productId: 0,
    productType: 0, // Replace with appropriate default or type
    avatar: "",
    name: "",
    minPrice: 0.0,
    maxPrice: 0.0,
};

function AddProductShopCatModal({ show, onClose, shopId, platform, shopCatId, onSave }) {
    const [listModel, setListModel] = useState([shopCatProductModel]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageSize: 20, shopId: shopId, platform: platform });

    useLayoutEffect(() => {
        setListModel([]);
    }, []);

    useEffect(() => {
        getModel({ ...paging, shopId, platform });
    }, [show]);

    const getModel = async (page) => {
        const [err, data] = await PlatformServices.GetShopCatProductModels({ ...page, shopId: shopId, platform: platform, productPushingStatus: ProductPushingStatus.Successed });
        if (!err && data) {
            setListModel(data.listObjects);
            setPaging(data.paging);
        }
    };

    const createUpdateShopCat = async () => {
        let list = listModel.filter((item) => item.isChecked);

        if (list == null || list.length == 0) {
            Notify(NOTIFY.ERROR, "Lỗi", "Vui lòng chọn sản phẩm");
            return;
        }

        const listData = list.map((item) => {
            return {
                shopCatId: shopCatId,
                productId: item.id,
            };
        });

        const [err, data] = await PlatformServices.AddRangeShopCat(listData);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, "Thành công", "Thêm sản phẩm vào danh mục thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", "Có lỗi xảy ra, vui lòng thử lại sau");
        }
    };

    return (
        <CommonModal
            title={"Thêm sản phẩm"}
            show={show}
            onClose={onClose}
            onSave={() => {
                createUpdateShopCat();
            }}
            size="lg"
        >
            <Wrapper>
                <div className="section_product">
                    <div className="section_product-top">
                        <div className="title">Danh sách sản phẩm</div>
                        <div className="add-product"></div>
                    </div>
                    <div className="section_product-content">
                        <div className="list-item">
                            {listModel && listModel.length > 0 ? (
                                listModel.map((item, idx) => {
                                    return (
                                        <div
                                            className={`item ${item.isChecked ? "active" : ""}`}
                                            key={idx}
                                            onClick={(e) => {
                                                let list = [...listModel];
                                                list[idx].isChecked = !list[idx].isChecked;
                                                setListModel(list);
                                            }}
                                        >
                                            <div className="image">{item.avatar && <img src={item.avatar} alt={item.name} />}</div>
                                            <div className="name">{item.name}</div>
                                            <div className="price">
                                                {Common.formatCurrency(item.minPrice)} - {Common.formatCurrency(item.maxPrice)}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-data">Không có dữ liệu</div>
                            )}
                        </div>
                        <div className="paging-bottom">
                            <CommonTablePagination
                                paging={paging}
                                onChangePage={(page) => {
                                    const newPaging = { ...paging, pageNumber: page };
                                    getModel(newPaging);
                                }}
                                onChangePageSize={(pageSize) => {
                                    const newPaging = { ...paging, pageNumber: 1, pageSize: pageSize };
                                    getModel(newPaging);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="section-bottom"></div>
            </Wrapper>
        </CommonModal>
    );
}

export default AddProductShopCatModal;

const Wrapper = styled.div`
    .section_product-content {
        margin-top: 20px;
    }

    .paging-bottom {
        margin-top: 20px;
    }

    .item.active {
        border: 1px solid #64c5b1;
        background: #e6fffa;
    }
    .item {
        margin: 8px;
        width: 190px;
        display: flex;
        flex-direction: column;
    }

    .name {
        margin: 6px 0;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .price {
        margin-top: auto;
    }
    .header_info {
        display: flex;
    }

    .avatar {
        width: 130px;
        height: 130px;
    }

    .avatar img {
        width: 130px;
        height: 130px;
        object-fit: contain;
    }

    .header_info-left {
        display: flex;
    }

    .name {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
    }

    .upload-btn {
        display: flex;
        margin-top: auto;
        background: #f1f1f1;
        justify-content: center;
        align-items: center;
        padding: 6px 15px;
    }

    .upload-btn .icon {
        margin-right: 8px;
    }

    .upload-btn span {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .section_product-top {
        display: flex;
        align-items: center;
    }

    .add-product {
        margin-left: auto;
    }

    .section_product {
        margin-top: 18px;
    }

    .title {
        color: #444;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 111.111% */
    }

    .list-item {
        display: flex;
        flex-wrap: wrap;
    }

    .image {
        width: 172px;
        height: 172px;
        flex-shrink: 0;
    }

    .image {
    }

    .image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .item {
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 8px;
        position: relative;
    }

    .item .name {
        color: #444;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-left: 0;
    }

    .price {
        color: #f96a74;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    .remove-icon {
        display: flex;
        position: absolute;
        background: #d70000;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        top: 5px;
        right: 5px;
    }

    .remove-icon .icon {
        display: flex;
    }
`;

const UploadIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5 15V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V15"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M17.5 8L12.5 3L7.5 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.5 3V15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

const XIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 4L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
