import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import CommonTable from "../based/CommonTable";
import { INITPAGING } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import PushingServices from "../based/services/PushingServices";
import PushingFileModal, { PushingPhotoshopFile } from "./PushingFileModal";
const FOLDER_TAB = [{ value: 0, label: "Tự thiết kế" }];

function PushingFileTotal(props) {
    const [activeFolder, setActiveFolder] = useState(0);
    const [datas, setDatas] = useState([PushingPhotoshopFile]);
    const [paging, setPaging] = useState({ ...INITPAGING, pageNumber: 1, pageSize: 50 });
    const [isShow, setIsShow] = useState(false);
    const [photoshopId, setPhotoshopId] = useState(0);

    useEffect(() => {
        _getData(paging);
    }, []);

    async function _getData(page) {
        let [err, data] = await PushingServices.GetPhotoshopFileByPage({ ...page });
        if (!err) {
            setDatas(data.listObjects);
            setPaging(data.paging);
        }
    }

    function _renderFolderTab() {
        return FOLDER_TAB.map((item, index) => {
            return (
                <li key={index} className="nav-item">
                    <a className={activeFolder == item.value ? "nav-link active" : "nav-link btn-light"} onClick={() => setActiveFolder(item.value)}>
                        <span>{item.label}</span>
                    </a>
                </li>
            );
        });
    }

    function _renderAction() {
        return (
            <React.Fragment>
                <button
                    className="btn btn-custom"
                    onClick={(e) => {
                        setPhotoshopId(0);
                        setIsShow(true);
                    }}
                >
                    Thêm mới
                </button>
            </React.Fragment>
        );
    }
    function _renderHeader() {
        return (
            <thead>
                <tr>
                    <th className="center">STT</th>
                    <th className="center">Thumbnail</th>
                    <th className="center">Platform Áp dụng</th>
                    <th className="center">Tên file PSD</th>
                    <th className="center">Link file PSD</th>
                    <th className="center">Dòng sản phẩm trên Avatar</th>
                    <th className="center">Dòng sản phẩm áp dụng</th>
                    <th className="center"></th>
                </tr>
            </thead>
        );
    }
    function _renderBody() {
        return (
            <tbody>
                {datas && datas.length > 0 ? (
                    datas.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td className="center">{idx + 1}</td>
                                <td className="center-image">
                                    <img src={item.thumbnail} />
                                </td>
                                <td className="center">
                                    {item.photoshopFileTypeName &&
                                        item.photoshopFileTypeName.map((item, index) => {
                                            return (
                                                <span className="badge m-1" key={index}>
                                                    {item}
                                                </span>
                                            );
                                        })}
                                </td>
                                <td className="center">{item.name}</td>
                                <td className="center">{item.fileUrl}</td>
                                <td className="center">{item.appProductName}</td>
                                <td className="center">
                                    {item.fileProducts &&
                                        item.fileProducts.map((item, index) => {
                                            return (
                                                <span className="badge m-1" key={index}>
                                                    {item.appProductName}
                                                </span>
                                            );
                                        })}
                                </td>
                                <td className="center">
                                    <button
                                        className="btn btn-custom m-2"
                                        onClick={(e) => {
                                            setPhotoshopId(item.id);
                                            setIsShow(true);
                                        }}
                                    >
                                        Chi tiết
                                    </button>
                                    <button
                                        className="btn btn-custom m-2"
                                        onClick={(e) => {
                                            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa dữ liệu đã chọn?", () => {
                                                return new Promise(async (resolve, reject) => {
                                                    let [err, data] = await PushingServices.DeletePhotoshopFile(item.id);
                                                    if (!err) {
                                                        resolve({ title: "Thành công", msg: "Xóa thành công." });
                                                        _getData(paging);
                                                    } else {
                                                        reject({ title: "Lỗi", msg: "Có lỗi xảy ra" });
                                                    }
                                                });
                                            });
                                        }}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={999}>Không có dữ liệu</td>
                    </tr>
                )}
            </tbody>
        );
    }

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Quản lý Folder Họa Tiết" />
            <ul className="nav nav-tabs tabs-bordered">{_renderFolderTab()}</ul>
            <CommonTable datas={datas} paging={paging} searchBasic onFilter={(paging) => _getData(paging)} renderAction={() => _renderAction()} renderHeader={() => _renderHeader()} renderBody={() => _renderBody()} />
            <PushingFileModal
                id={photoshopId}
                isShowModal={isShow}
                onClose={() => setIsShow(false)}
                onSave={() => {
                    _getData(paging);
                    setIsShow(false);
                }}
            />
        </Wrapper>
    );
}

export default PushingFileTotal;

const Wrapper = styled.div`
    td.center-image {
        width: 200px;
        height: 200px;
    }

    .center-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
