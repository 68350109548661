import React, { useEffect, useMemo, useRef, useState } from "react";
import Barcode from "react-barcode";
import { Overlay, Popover } from "react-bootstrap";
import Breadcrumbs from "../based/Breadcrumbs";
import { ARTWORK_TYPE, NOTIFY, ORDER_STATUS, PACKAGE_ORDER_STATUS, PLATFORM, POSITION_LEVEL_NAME, PRINT_TYPE, SHIPPING_SERVICES_CODE, STATUS_TRANSACTION } from "../based/Constants";
import { ConfirmDialog } from "../based/Dialog";
import Loading from "../based/Loading";
import { Notify } from "../based/Notify";
import CommonServices from "../based/services/CommonServices";
import OperatorServices from "../based/services/OperatorServices";
import OrderServices from "../based/services/OrderServices";
import OrderBookingModal from "./OrderBookingModal";
import useUnsavedChangesWarning from "./useUnsavedChangesWarning";
import Radio from "../based/inputs/Radio";

export default function OrderPacking(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("Đang tải dữ liệu...");
    const [packings, setPackings] = useState([]);
    const [barcode, setBarcode] = useState("");
    const [target, setTarget] = useState(null);
    const [overlayShow, setOverlayShow] = useState(false);
    const [overlayData, setOverlayData] = useState(null);
    const [currentPacking, setCurrentPacking] = useState(null);
    const [showBooking, setShowBooking] = useState(false);
    const [visibleShipped, setVisibleShipped] = useState(false);
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning("Đơn hàng chưa hoàn tất đóng gói. Bạn chắc chắn muốn rời khởi màn hình làm việc?");
    const ref = useRef(null);

    //đơn vị vận chuyển
    const [shippingServices, setShippingServices] = useState([]);
    const [shippingCompanyId, setShippingCompanyId] = useState(0);

    //lấy id đơn hàng
    const orderId = useMemo(() => {
        if (currentPacking && currentPacking.operatorOrderTicket) return currentPacking.operatorOrderTicket.orderId;
        return 0;
    }, [currentPacking]);

    const platform = useMemo(() => {
        if (currentPacking && currentPacking.operatorOrderTicket) return currentPacking.operatorOrderTicket.platform;
        return 0;
    }, [currentPacking]);

    const shippingCode = useMemo(() => {
        if (shippingServices && shippingServices.length > 0) {
            let find = shippingServices.find((x) => x.id == shippingCompanyId);
            if (find) return find.code;
        }
        return "";
    }, [shippingServices, shippingCompanyId]);

    console.log("packings", packings);
    console.log("currentPacking", currentPacking);

    useEffect(() => {
        //focus vào barcode
        let content = document.querySelector(".content");
        if (content) {
            var contentHeight = content.offsetHeight;
            let boxBarcode = document.querySelector("#box-barcode");
            let boxBarcodeHeight = boxBarcode ? boxBarcode.offsetHeight : 300;
            var height = contentHeight - 130 - boxBarcodeHeight;
            if (height > 0) {
                document.getElementById("tbl_items").style.overflowY = "auto";
                document.getElementById("tbl_items").style.height = height + "px";
            }
        }
        document.getElementById("barcode").focus();

        //lấy thông tin vận chuyển
        _getShippingServices();
    }, []);

    async function _getShippingServices() {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [err, data] = await CommonServices.GetShippingServices();
        if (!err && data) {
            setShippingServices(data);
            var spx = data.find((x) => x.code == SHIPPING_SERVICES_CODE.SHOPEEXPRESS);
            if (spx) setShippingCompanyId(spx.id);
        } else {
            setShippingCompanyId(0);
        }
        setMsg("");
        setIsLoading(false);
    }

    //xóa đóng gói sản phẩm
    function _handleRemoveScanTag(tag, item) {
        if (tag) {
            ConfirmDialog("Xác nhận xóa?", "Bạn chắc chắn muốn xóa đóng gói sản phẩm này?", () => {
                return new Promise(async (resolve, reject) => {
                    let packingUp = { ...currentPacking };
                    if (packingUp) {
                        var packingItemsOld = packingUp.operatorOrderPackingItems;
                        if (packingItemsOld && packingItemsOld.length > 0) {
                            var currentProduct = packingItemsOld.find((x) => x.sellerSKU == item.sellerSKU);
                            if (currentProduct && currentProduct.productionScanTags && currentProduct.productionScanTags.length > 0) {
                                let idx = -1;
                                currentProduct.productionScanTags.map((scanTag, s) => {
                                    if (scanTag == tag) idx = s;
                                });
                                if (idx > -1) {
                                    currentProduct.productionScanTags.splice(idx, 1);
                                    setCurrentPacking(packingUp);
                                    //set lại list packings
                                    let packingsUp = [...packings];
                                    if (packingsUp && packingsUp.length > 0) {
                                        packingsUp.map((item) => {
                                            if (item.operatorOrderTicket.orderId == packingUp.operatorOrderTicket.orderId) item = packingUp;
                                        });
                                        setPackings(packingsUp);
                                    }

                                    resolve({ title: "Thành công", msg: "Xóa thành công." });
                                }
                            }
                        }
                    }
                });
            });
        }
    }

    //lấy thông tin tag trong table
    function _handleClickTag(tagId) {
        setBarcode(`${tagId}`);
        _handleScanBarcode(tagId.toString());
    }

    //kiểm tra và lưu thông tin người đóng gói
    async function _handleCheckSavePackageHandle(orderId) {
        if (!currentPacking || (currentPacking.operatorOrderTicket && currentPacking.operatorOrderTicket.orderId != orderId)) {
            let [error, data] = await OperatorServices.CheckSavePackageHandle(orderId);
            if (!error && data) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    //process scan barcode mới
    async function _processingScanBarcode(data) {
        let packingUp = { ...currentPacking };
        if (packingUp != null && packingUp.operatorOrderTicket && data.operatorOrderTicket && packingUp.operatorOrderTicket.orderId == data.operatorOrderTicket.orderId) {
            var packingItemsOld = packingUp.operatorOrderPackingItems;
            if (packingItemsOld && packingItemsOld.length > 0) {
                packingItemsOld.map((item) => {
                    if (item.productionTags && item.productionTags.length > 0) {
                        let scanTags = [];
                        if (item.productionScanTags && item.productionScanTags.length > 0) scanTags = item.productionScanTags;
                        item.productionTags.map((tag) => {
                            if (`${tag.tagId}` == barcode && !scanTags.includes(barcode)) scanTags.push(`${tag.tagId}`);
                        });
                        item.productionScanTags = scanTags;
                    }
                });
            }
        } else {
            if (data.operatorOrderPackingItems && data.operatorOrderPackingItems.length > 0) {
                data.operatorOrderPackingItems.map((item) => {
                    if (item.productionTags && item.productionTags.length > 0) {
                        let scanTags = [];
                        item.productionTags.map((tag) => {
                            if (`${tag.tagId}` == barcode && !scanTags.includes(barcode)) scanTags.push(`${tag.tagId}`);
                        });
                        item.productionScanTags = scanTags;
                    }
                });
            }
            packingUp = data;
        }
        setCurrentPacking(packingUp);

        //set lại list packings
        let packingsUp = [...packings];
        if (packingsUp && packingsUp.length > 0) {
            let isExisted = packingsUp.find((x) => x.operatorOrderTicket.orderId == packingUp.operatorOrderTicket.orderId) != null;
            if (isExisted)
                packingsUp.map((item) => {
                    if (item.operatorOrderTicket.orderId == packingUp.operatorOrderTicket.orderId) item = packingUp;
                });
            else {
                packingsUp.push(packingUp);
            }
        } else {
            let arr = [];
            arr.push(packingUp);
            packingsUp = arr;
        }
        setPackings(packingsUp);
    }

    //lấy thông tin barcode, nếu barcode không trùng với barcode hiện tại thì lấy dữ liệu mới, mở tab mới
    async function _handleScanBarcode(barcode) {
        if (barcode) {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
            let [error, data] = await OperatorServices.GetOperatorOrderPackingItem(barcode);
            if (!error && data) {
                if (data != null) {
                    if (data.operatorOrderTicket) {
                        if (data.operatorOrderTicket.orderId && currentPacking && currentPacking.operatorOrderTicket && data.operatorOrderTicket.orderId != currentPacking.operatorOrderTicket.orderId) {
                            let isCompleted = false;
                            if (data && data.operatorOrderTicket)
                                data.operatorOrderTicket.packageOrderStatus == PACKAGE_ORDER_STATUS.FULL_PACK && (data.operatorOrderTicket.status == STATUS_TRANSACTION.SUCCEED || data.operatorOrderTicket.status == STATUS_TRANSACTION.SHIPPED);
                            if (isCompleted) {
                                Notify(NOTIFY.WARNING, "Chú ý", `Đơn hàng ${data.operatorOrderTicket.orderNumber} đã hoàn tất đóng gói trước đó.`);
                            } else {
                                //kiểm tra và lưu thông tin người đóng gói
                                var isCheck = await _handleCheckSavePackageHandle(data.operatorOrderTicket.orderId);
                                if (isCheck) {
                                    //nếu chưa được ai xử lý
                                    ConfirmDialog(
                                        "Xác nhận?",
                                        "Barcode không thuộc đơn hàng đang đóng gói. Bạn có muốn mở tab xử lý mới cho đơn hàng này?",
                                        () => {
                                            return new Promise(async (resolve, reject) => {
                                                await _processingScanBarcode(data);
                                                resolve("");
                                            });
                                        },
                                        true
                                    );
                                } else {
                                    Notify(NOTIFY.WARNING, "Chú ý", `Đơn hàng ${data.operatorOrderTicket.orderNumber} đang được xử lý bởi người khác`);
                                }
                            }
                        } else {
                            let isCompleted = false;
                            if (data && data.operatorOrderTicket)
                                isCompleted =
                                    data.operatorOrderTicket.packageOrderStatus == PACKAGE_ORDER_STATUS.FULL_PACK && (data.operatorOrderTicket.status == STATUS_TRANSACTION.SUCCEED || data.operatorOrderTicket.status == STATUS_TRANSACTION.SHIPPED);
                            if (isCompleted) {
                                Notify(NOTIFY.WARNING, "Chú ý", "Đơn hàng đã hoàn thiện trước đó.");
                            } else {
                                //kiểm tra và lưu thông tin người đóng gói
                                var isCheck = await _handleCheckSavePackageHandle(data.operatorOrderTicket.orderId);
                                if (isCheck) {
                                    let packingUp = { ...currentPacking };
                                    if (packingUp != null && packingUp.operatorOrderTicket && data.operatorOrderTicket && packingUp.operatorOrderTicket.orderId == data.operatorOrderTicket.orderId) {
                                        var packingItemsOld = packingUp.operatorOrderPackingItems;
                                        if (packingItemsOld && packingItemsOld.length > 0) {
                                            packingItemsOld.map((item) => {
                                                if (item.productionTags && item.productionTags.length > 0) {
                                                    let scanTags = [];
                                                    if (item.productionScanTags && item.productionScanTags.length > 0) scanTags = item.productionScanTags;
                                                    item.productionTags.map((tag) => {
                                                        if (`${tag.tagId}` == barcode && !scanTags.includes(barcode)) scanTags.push(`${tag.tagId}`);
                                                    });
                                                    item.productionScanTags = scanTags;
                                                }
                                            });
                                        }
                                        packingUp.operatorOrderTicket = data.operatorOrderTicket;
                                    } else {
                                        if (data.operatorOrderPackingItems && data.operatorOrderPackingItems.length > 0) {
                                            data.operatorOrderPackingItems.map((item) => {
                                                if (item.productionTags && item.productionTags.length > 0) {
                                                    let scanTags = [];
                                                    item.productionTags.map((tag) => {
                                                        if (`${tag.tagId}` == barcode && !scanTags.includes(barcode)) scanTags.push(`${tag.tagId}`);
                                                    });
                                                    item.productionScanTags = scanTags;
                                                }
                                            });
                                        }
                                        packingUp = data;
                                    }
                                    setCurrentPacking(packingUp);
                                    let packingsUp = [...packings];
                                    if (packingsUp && packingsUp.length > 0) {
                                        let isExisted = packingsUp.find((x) => x.operatorOrderTicket.orderNumber == packingUp.operatorOrderTicket.orderNumber) != null;
                                        if (isExisted)
                                            packingsUp.map((item) => {
                                                if (item.operatorOrderTicket.orderId == packingUp.operatorOrderTicket.orderId) item = packingUp;
                                            });
                                        else {
                                            packingsUp.push(packingUp);
                                        }
                                    } else {
                                        let arr = [];
                                        arr.push(packingUp);
                                        packingsUp = arr;
                                    }
                                    setPackings(packingsUp);
                                } else {
                                    Notify(NOTIFY.WARNING, "Chú ý", "Đơn hàng đang được xử lý bởi người khác");
                                }
                            }
                        }
                    } else {
                        Notify(NOTIFY.WARNING, "Chú ý", "Không tìm thấy thông tin Barcode");
                    }
                }
                setIsLoading(false);
                setMsg("");
                setBarcode("");
            } else {
                setIsLoading(false);
                setMsg("");
                Notify(NOTIFY.WARNING, "Chú ý", error.message ? error.message : "Có lỗi trong quá trình lấy thông tin");
            }
        } else {
            Notify(NOTIFY.WARNING, "Chú ý", "Yêu cầu nhập Barcode");
            document.getElementById("barcode").focus();
        }
    }

    //show thông tin image artwork
    function _handleClickMockup(e, mockup) {
        setTarget(e.target);
        setOverlayShow(!overlayShow);
        setOverlayData(mockup);
    }

    //hoàn tất đóng gói đơn hàng
    async function _handleCompleted() {
        setPristine();
        ConfirmDialog("Xác nhận?", "Bạn chắc chắn muốn hoàn tất đóng gói đơn hàng này?", () => {
            return new Promise(async (resolve, reject) => {
                setIsLoading(true);
                setMsg("Đang lưu dữ liệu...");
                let orderId = currentPacking && currentPacking.operatorOrderTicket ? currentPacking.operatorOrderTicket.orderId : 0;
                if (orderId > 0) {
                    let [error, data] = await OperatorServices.UpdatePackageFullPack(PACKAGE_ORDER_STATUS.FULL_PACK, orderId);
                    if (!error && data) {
                        setIsLoading(false);
                        setMsg(null);
                        resolve({ title: "Thành công", msg: "Đã hoàn thành đóng gói đơn hàng." });
                        let packingUp = { ...currentPacking };
                        if (packingUp && packingUp.operatorOrderTicket) packingUp.operatorOrderTicket.packageOrderStatus = PACKAGE_ORDER_STATUS.FULL_PACK;
                        setCurrentPacking(packingUp);
                        //set lại list packings
                        let packingsUp = [...packings];
                        if (packingsUp && packingsUp.length > 0) {
                            packingsUp.map((item) => {
                                if (item.operatorOrderTicket.orderId == packingUp.operatorOrderTicket.orderId) item = packingUp;
                            });
                            setPackings(packingsUp);
                        }
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.WARNING, "Có lỗi", "Có lỗi xảy ra trong quá trình cập nhật trạng thái đóng gói đơn hàng.");
                    }
                }
            });
        });
    }

    //in nhãn ship
    async function _handlePrint() {
        //nếu đơn là đơn Ranus thì show Popup in nhãn ship
        if (platform == PLATFORM.Internal) {
            setShowBooking(true);
        }
        //nếu đơn là của Lazada/Shopee thì call api để tạo đơn
        else {
            setIsLoading(true);
            setMsg("Đang tải dữ liệu...");
            let [error, data] = await OrderServices.PrintShippingLabel(orderId, 0);
            if (!error && data) {
                switch (platform) {
                    case PLATFORM.Lazada:
                        window.open(data.url, "_blank", "noopener,noreferrer");
                        break;
                    case PLATFORM.Shopee:
                        if (data.url) {
                            let arr = data.url.split(",");
                            if (arr && arr.length > 0) {
                                arr.map((url) => window.open(url, "_blank", "noopener,noreferrer"));
                            }
                        }
                        break;
                    default:
                        break;
                }
                let packingUp = { ...currentPacking };
                packingUp.operatorOrderTicket.totalPrintLabelShip += 1;
                setCurrentPacking(packingUp);
                let packingsUp = [...packings];
                if (packingsUp && packingsUp.length > 0) {
                    packingsUp.map((item) => {
                        if (item.operatorOrderTicket.orderId == packingUp.operatorOrderTicket.orderId) item = packingUp;
                    });
                    setPackings(packingsUp);
                }
                setIsLoading(false);
                setMsg(null);
            } else {
                setIsLoading(false);
                setMsg(null);
                Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
            }
        }
    }

    async function _handleDirectPrint() {
        //nếu đơn Internal (Ranus)
        if (platform == PLATFORM.Internal) {
            handleInteralPrint();
        } else {
            //nếu đơn External (Lazada, Shopee)
            handleExternalPrint();
        }
    }

    //chuyển đơn bàn giao cho shipper
    function _handleSetStatusToShipped() {
        if (currentPacking && currentPacking.operatorOrderTicket) {
            ConfirmDialog("Xác nhận?", "Bạn chắc chắn muốn chuyển trạng thái 'Bàn giao cho Shipper' cho đơn hàng này?", () => {
                return new Promise(async (resolve, reject) => {
                    let orderId = currentPacking.operatorOrderTicket.orderId;
                    setIsLoading(true);
                    setMsg("Đang lưu dữ liệu...");
                    let [error, data] = await OrderServices.ChangeOrderStatus(orderId, ORDER_STATUS.Shipped);
                    if (!error) {
                        resolve({ title: "Thành công", msg: "Đơn hàng đã được bàn giao cho Shipper." });
                        setIsLoading(false);
                        setMsg(null);
                    } else {
                        setIsLoading(false);
                        setMsg(null);
                        Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình cập nhật trạng thái");
                    }
                });
            });
        }
    }

    //thay đổi tab đơn hàng
    function _handleChangeTab(item) {
        if (item && item.operatorOrderPackingItems && item.operatorOrderPackingItems.length > 0) {
            setCurrentPacking(item);
            setBarcode("");
            document.getElementById("barcode").focus();
        }
    }

    // tab đơn hàng
    function _renderTabOrder() {
        if (packings && packings.length > 1) {
            return (
                <ul className="nav nav-tabs tabs-bordered">
                    {packings.map((item, index) => {
                        return (
                            item.operatorOrderTicket && (
                                <li key={index} className="nav-item">
                                    <a
                                        className={currentPacking && currentPacking.operatorOrderTicket && currentPacking.operatorOrderTicket.orderId == item.operatorOrderTicket.orderId ? "nav-link active" : "nav-link btn-light"}
                                        onClick={() => _handleChangeTab(item)}
                                    >
                                        <span>Đơn hàng&nbsp;{item.operatorOrderTicket.orderNumber ? item.operatorOrderTicket.orderNumber : item.operatorOrderTicket.orderId}</span>
                                    </a>
                                </li>
                            )
                        );
                    })}
                </ul>
            );
        }
    }

    // button đóng gói
    function _renderButtons() {
        let isEnableCompleted = false;
        let isEnablePrint = false;
        let totalPrintLabelShip = 0;
        if (currentPacking) {
            if (currentPacking.operatorOrderPackingItems && currentPacking.operatorOrderPackingItems.length > 0) {
                let countTags = 0;
                let countScanTags = 0;
                currentPacking.operatorOrderPackingItems.map((item) => {
                    countTags += item.productionTags.length;
                    countScanTags += item.productionScanTags.length;
                });
                isEnableCompleted = countTags == countScanTags;
            }
            if (currentPacking.operatorOrderTicket) {
                isEnablePrint = currentPacking.operatorOrderTicket.packageOrderStatus == PACKAGE_ORDER_STATUS.FULL_PACK;
                totalPrintLabelShip = currentPacking.operatorOrderTicket.totalPrintLabelShip;
            }
        }
        return (
            <div className="form-group col-md-12">
                <button className={isEnableCompleted ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={() => (isEnableCompleted ? _handleCompleted() : {})}>
                    <i className="fa fa-check m-r-5" />
                    Hoàn tất
                </button>
                <button className={isEnablePrint ? "btn btn-custom btn-sm m-r-5" : "btn btn-custom btn-sm m-r-5 disable"} onClick={() => (isEnablePrint ? _handleDirectPrint() : {})}>
                    <i className="fa fa-print m-r-5" />
                    In nhãn Ship ({totalPrintLabelShip})
                </button>

                {visibleShipped && (
                    <button className="btn btn-custom btn-sm m-r-5" onClick={() => _handleSetStatusToShipped()}>
                        <i className="fa fa-paper-plane-o m-r-5" />
                        Bàn giao cho Shipper
                    </button>
                )}
                <strong className="numberorder-title">
                    Mã đơn hàng: {currentPacking && currentPacking.operatorOrderTicket ? (currentPacking.operatorOrderTicket.orderNumber ? currentPacking.operatorOrderTicket.orderNumber : currentPacking.operatorOrderTicket.orderId) : "---"}
                </strong>
                {currentPacking && currentPacking.operatorOrderTicket && currentPacking.operatorOrderTicket.platformName && <strong className="text-uppercase">&ensp;-&ensp;{currentPacking.operatorOrderTicket.platformName}</strong>}
                {currentPacking && currentPacking.operatorOrderTicket && currentPacking.operatorOrderTicket.packageOrderStatus == PACKAGE_ORDER_STATUS.FULL_PACK && (
                    <span className="rounded text-white status label-success pull-right">Đã hoàn tất đóng gói</span>
                )}
            </div>
        );
    }

    // button đơn vị vận chuyển
    function _renderShippingServices() {
        return (
            <div className="form-group col-md-12">
                <div className="list-shipping-service">
                    {shippingServices &&
                        shippingServices.length > 0 &&
                        shippingServices.map((item, idx) => {
                            return (
                                <Radio
                                    classDiv="mr-3"
                                    key={idx}
                                    id={`ship-${item.id}`}
                                    name="shippingCompanyId"
                                    checked={shippingCompanyId == item.id}
                                    value={item.id}
                                    onCheckedChange={(name, value) => setShippingCompanyId(item.id)}
                                    label={`${item.name}`}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }

    const handleInteralPrint = async () => {
        switch (shippingCode) {
            case SHIPPING_SERVICES_CODE.RANUS:
                //call api in nhãn ship
                await APIServices.HandlePrintRanus(orderId, shippingCompanyId);
                break;
            case SHIPPING_SERVICES_CODE.VNPOST:
                //call api in nhãn ship
                await APIServices.HandlePrintVNPost(orderId, shippingCompanyId);
                break;
            case SHIPPING_SERVICES_CODE.SHOPEEXPRESS:
                //call api in nhãn ship
                await APIServices.HandlePrintSPX(orderId, shippingCompanyId);
                break;
            default:
                break;
        }

        //chuyển sang trạng thái sẵn sàng giao
        await APIServices.HandleReadyToShip(orderId);

        //bật label in nhãn ship
        window.open(`/shipping-label/${orderId}`, "_blank");
    };

    const handleExternalPrint = async () => {
        setIsLoading(true);
        setMsg("Đang tải dữ liệu...");
        let [error, data] = await OrderServices.PrintShippingLabel(orderId, 0);
        if (!error && data) {
            switch (platform) {
                case PLATFORM.Lazada:
                    window.open(data.url, "_blank", "noopener,noreferrer");
                    break;
                case PLATFORM.Shopee:
                    if (data.url) {
                        let arr = data.url.split(",");
                        if (arr && arr.length > 0) {
                            arr.map((url) => window.open(url, "_blank", "noopener,noreferrer"));
                        }
                    }
                    break;
                default:
                    break;
            }
            let packingUp = { ...currentPacking };
            packingUp.operatorOrderTicket.totalPrintLabelShip += 1;
            setCurrentPacking(packingUp);
            let packingsUp = [...packings];
            if (packingsUp && packingsUp.length > 0) {
                packingsUp.map((item) => {
                    if (item.operatorOrderTicket.orderId == packingUp.operatorOrderTicket.orderId) item = packingUp;
                });
                setPackings(packingsUp);
            }
            setIsLoading(false);
            setMsg(null);
        } else {
            setIsLoading(false);
            setMsg(null);
            Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
        }
    };

    const APIServices = {
        HandlePrintSPX: async (orderId, shippingCompanyId) => {
            const [error, data] = await OrderServices.PrintShippingLabel(orderId, shippingCompanyId);
            if (!error && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "In nhãn ship thành công");
            } else {
                Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
            }
        },
        HandlePrintVNPost: async (orderId, shippingCompanyId) => {
            const [error, data] = await OrderServices.PrintShippingLabel(orderId, shippingCompanyId);
            if (!error && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "In nhãn ship thành công");
            } else {
                Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
            }
        },
        HandlePrintRanus: async (orderId, shippingCompanyId) => {
            const [error, data] = await OrderServices.PrintShippingLabelRanus(orderId, shippingCompanyId);
            if (!error && data) {
                Notify(NOTIFY.SUCCESS, "Thành công", "In nhãn ship thành công");
            } else {
                Notify(NOTIFY.ERROR, "Lỗi", error.message ? error.message : "Có lỗi xảy ra trong quá trình in nhãn ship");
            }
        },
        HandleReadyToShip: async (orderId) => {
            const [err, data] = await OrderServices.SetStatusToReadyToShip(orderId);
        },
    };

    //table đơn hàng
    function _renderProducts() {
        let orderTicket = currentPacking && currentPacking.operatorOrderTicket ? currentPacking.operatorOrderTicket : null;
        return (
            <div className="content-table table-rep-plugin full-width" id="tbl_items">
                <table className="table table-bordered m-b-0 focus-on packing">
                    <thead>
                        <tr>
                            <th width={40} className="text-center">
                                STT
                            </th>
                            <th className="text-center">SKU ID</th>
                            <th className="text-center" width="100">
                                Số lượng
                            </th>
                            <th className="text-center">Tag sản xuất</th>
                            <th className="text-center">Loại áo</th>
                            {orderTicket &&
                                orderTicket.attributeValues &&
                                orderTicket.attributeValues.length > 0 &&
                                orderTicket.attributeValues.map((attr, k) => {
                                    return (
                                        <React.Fragment key={k}>
                                            <th className="text-center" width="100">
                                                {attr.name}
                                            </th>
                                        </React.Fragment>
                                    );
                                })}
                            <th className="text-center">Sản phẩm đóng gói</th>
                            <th className="text-center" width="100">
                                Brand Logo
                            </th>
                            <th className="text-center" width="100">
                                Loại in
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPacking && currentPacking.operatorOrderPackingItems && currentPacking.operatorOrderPackingItems.length > 0 ? (
                            currentPacking.operatorOrderPackingItems.map((item, k) => {
                                return (
                                    <tr key={k}>
                                        <td className="text-center">{k + 1}</td>
                                        <td className="text-center">{item.sellerSKU}</td>
                                        <td className="text-center">{item.quantity}</td>
                                        <td className="text-center">{_renderProductionTags(item)}</td>
                                        <td className="text-center">{item.productTypeName}</td>
                                        {item.attributeValue &&
                                            item.attributeValue.length > 0 &&
                                            item.attributeValue.map((attr, a) => {
                                                return (
                                                    <td key={a} className="text-center">
                                                        {attr.value}
                                                    </td>
                                                );
                                            })}
                                        <td className="text-center">{_renderProductionScanTags(item)}</td>
                                        <td className="text-center">{item.isBrandStamp ? <span>Có</span> : <span>Không</span>}</td>
                                        <td className="text-center">{item.printType == PRINT_TYPE.DTG ? <span>DTG</span> : <span>PET</span>}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="100" className="text-center">
                                    <span>Không có dữ liệu</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    function _renderProductionTags(item) {
        if (item.productionTags && item.productionTags.length > 0) {
            let tags = [];
            item.productionTags.map((tag, idx) => {
                let cls = "cursor-pointer";
                if (tag.isBold) cls += " font-weight-bold";
                if (tag.isHighLight) cls += " mark-yellow";
                if (tag.isUnderLine) cls += " text-decoration-underline";
                tags.push(
                    <span key={idx} className={cls} onClick={() => _handleClickTag(tag.tagId)}>
                        {tag.tagId}
                    </span>
                );
                if (idx < item.productionTags.length - 1)
                    tags.push(
                        <span className="m-r-5" key={"99999"}>
                            ,
                        </span>
                    );
            });
            return tags;
        }
    }

    function _renderProductionScanTags(item) {
        if (item.productionScanTags && item.productionScanTags.length > 0) {
            let tags = [];
            let visibleRemove = false;
            if (currentPacking && currentPacking.operatorOrderTicket) visibleRemove = currentPacking.operatorOrderTicket.packageOrderStatus != PACKAGE_ORDER_STATUS.FULL_PACK;
            item.productionScanTags.map((tag, idx) => {
                tags.push(
                    <span className="scan-tag" key={idx}>
                        {tag}
                        {visibleRemove && (
                            <span className="remove-scan-tag" onClick={() => _handleRemoveScanTag(tag, item)}>
                                <i className="fa fa-times-circle"></i>
                            </span>
                        )}
                    </span>
                );
            });
            return tags;
        }
    }

    //section barcode
    function _renderSectionBarcode() {
        let orderTicket = currentPacking && currentPacking.operatorOrderTicket ? currentPacking.operatorOrderTicket : {};
        let logoMockup = orderTicket.logoMockupModel ? orderTicket.logoMockupModel : null;
        let artworkMockups = orderTicket.artworkMockupModels ? orderTicket.artworkMockupModels : null;
        return (
            <React.Fragment>
                <div className="form-group col-md-3 order-packing">
                    <div className="order-information">
                        <div className="order-detail-list">
                            <div>
                                <span>Work-art: ---</span>
                            </div>
                            <div>
                                <span>
                                    Brand: <strong>{orderTicket.brandName ? orderTicket.brandName : "---"}</strong>
                                </span>
                            </div>
                            <div>
                                <span>
                                    Creator: <strong>{orderTicket.creatorName ? orderTicket.creatorName : "---"}</strong>
                                </span>
                            </div>
                            <div>
                                <span>
                                    Mã đơn hàng: <strong>{orderTicket.orderNumber ? orderTicket.orderNumber : orderTicket.orderId}</strong>
                                </span>
                            </div>
                            <div>
                                <span>
                                    Tên người mua: <strong>{orderTicket.customerName ? orderTicket.customerName : "---"} </strong>
                                </span>
                            </div>
                            <div>
                                <span>
                                    Ngày đặt hàng: <strong>{orderTicket.purchaseDateDisplay ? orderTicket.purchaseDateDisplay : "---"}</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="form-row m-t-20">
                        <div className="col-md-9">
                            <input
                                autoComplete="off"
                                id="barcode"
                                name="barcode"
                                className="form-control text-center font-weight-bold"
                                type="text"
                                placeholder="Nhập barcode"
                                maxLength={200}
                                onChange={(e) => {
                                    setBarcode(e.target.value);
                                    setDirty();
                                }}
                                value={barcode}
                                onKeyPress={(e) => {
                                    if (e.charCode === 13) _handleScanBarcode(barcode);
                                }}
                            ></input>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-custom btn-sm full-width" onClick={() => _handleScanBarcode(barcode)}>
                                Nhập
                            </button>
                        </div>
                    </div>

                    <div className="form-row m-t-10">
                        <div className="col-md-12 text-center">
                            <Barcode value={barcode ? `${barcode}` : "00000000"} height={50} format="CODE128" />
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-9">
                    {logoMockup && logoMockup.url && logoMockup.url.length > 0 && (
                        <div className="form-group col-md-12 brand-logo">
                            <div className="image-item">
                                <div className="form-row">
                                    <div className="col-md-3">
                                        <div className="mockup-image">
                                            <div className="full-width">
                                                <img src={logoMockup.url} alt="Brand Logo" className="full-width" style={{ objectFit: "contain", maxHeight: 60 }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mockup-detail">
                                        <strong>Brand Logo</strong>
                                        <div>
                                            <span>
                                                Logo Position: <strong>{POSITION_LEVEL_NAME[logoMockup.positionLevel]}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {artworkMockups && artworkMockups.length > 0 && (
                        <div className="form-row">
                            {artworkMockups.map((art, k) => {
                                return (
                                    art.artworkType != ARTWORK_TYPE.None && (
                                        <div key={k} className="form-group col-md-6 order-packing mock-up-item">
                                            <div
                                                className="image-item"
                                                onClick={(e) => {
                                                    _handleClickMockup(e, art);
                                                }}
                                            >
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="mockup-image">
                                                            <div className="full-width">
                                                                <img src={art.url} alt="Mock-up" className="full-width" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mockup-detail">
                                                        <strong>{art.artworkType == ARTWORK_TYPE.Front ? "Front Printing" : "Back Printing"}</strong>
                                                        <div>
                                                            <span>Image width:&nbsp;{art.imageWidth ? art.imageWidth.toFixed(2) : 0}&nbsp;cm</span>
                                                        </div>
                                                        <div>
                                                            <span>Image height:&nbsp;{art.imageHeight ? art.imageHeight.toFixed(2) : 0}&nbsp;cm </span>
                                                        </div>
                                                        <div>
                                                            <span>Front top:&nbsp;{art.fromTop ? art.fromTop.toFixed(2) : 0}&nbsp;cm </span>
                                                        </div>
                                                        <div>
                                                            <span>Front mid:&nbsp;{art.fromMid ? art.fromMid.toFixed(2) : 0}&nbsp;cm </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }

    return (
        <Wrapper>
            {Prompt}
            <Loading show={isLoading} msg={msg} />
            <Breadcrumbs parentPage="Quản lý đóng gói" hrefParent="/operator-order-packing" currentPage="Đóng gói đơn hàng" />
            {_renderTabOrder()}
            <div className="col-sm-12 card-box">
                <div className="form-row m-t-20">
                    {_renderButtons()}
                    {_renderShippingServices()}
                    <div className="form-group col-md-12">{_renderProducts()}</div>
                    <div className="form-group col-md-12 form-row" id="box-barcode">
                        {_renderSectionBarcode()}
                    </div>
                </div>
            </div>
            {showBooking && (
                <OrderBookingModal
                    isShowModal={showBooking}
                    orderId={currentPacking && currentPacking.operatorOrderTicket ? currentPacking.operatorOrderTicket.orderId : 0}
                    onClose={() => setShowBooking(false)}
                    onSuccess={(shippingCode) => {
                        setShowBooking(false);
                        if (shippingCode == SHIPPING_SERVICES_CODE.RANUS) {
                            setVisibleShipped(true);
                        } else {
                            setVisibleShipped(false);
                        }
                    }}
                />
            )}
            {overlayShow && (
                <OverlayElement
                    show={overlayShow}
                    target={target}
                    onClose={() => {
                        setOverlayData(null);
                        setOverlayShow(false);
                    }}
                    container={ref.current}
                    data={overlayData}
                />
            )}
        </Wrapper>
    );
}

import styled from "styled-components";
const Wrapper = styled.div`
    .list-shipping-service {
        display: flex;
        align-items: center;
    }

    .list-shipping-service .radio.radio-success.mr-3 {
        margin: 0 10px;
    }
`;

const OverlayElement = (props) => {
    let art = props.data ? props.data : {};
    let popover = (
        <Popover id={"popover-mockup"}>
            <Popover.Content>
                <div className="form-group col-md-12 order-packing mock-up-item">
                    <div className="image-item">
                        <div className="form-row">
                            <div className="col-md-8">
                                <div className="mockup-image">
                                    <div className="full-width">
                                        <img src={art.url} alt="Mock-up" className="full-width" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mockup-detail">
                                <strong>{art.artworkType == ARTWORK_TYPE.Front ? "Front Printing" : "Back Printing"}</strong>
                                <div>
                                    <span>Image width:&nbsp;{art.imageWidth ? art.imageWidth.toFixed(2) : 0}&nbsp;cm</span>
                                </div>
                                <div>
                                    <span>Image height:&nbsp;{art.imageHeight ? art.imageHeight.toFixed(2) : 0}&nbsp;cm </span>
                                </div>
                                <div>
                                    <span>Front top:&nbsp;{art.fromTop ? art.fromTop.toFixed(2) : 0}&nbsp;cm </span>
                                </div>
                                <div>
                                    <span>Front mid:&nbsp;{art.fromMid ? art.fromMid.toFixed(2) : 0}&nbsp;cm </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <Overlay rootClose={true} onHide={() => props.onClose()} show={props.show} target={props.target} container={props.container} containerPadding={20}>
            {popover}
        </Overlay>
    );
};
