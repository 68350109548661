import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { CONSTANTS, MAINMENU, NOTIFY, STATUS_ACTIVE, PLATFORM } from "../../based/Constants";
import Request from "../../based/Request";
import Loading from "../../based/Loading";
import { Notify } from "../../based/Notify";
import PlatformServices from "../../based/services/PlatformServices";
import { ConfirmDialog } from "../../based/Dialog";

export default function LazadaConfig(props) {
    const [isBusy, setBusy] = useState(true);
    const [message, setMessage] = useState("Đang tải dữ liệu...");
    const [stores, setStores] = useState([]);
    const [storeSelected, setStoreSelected] = useState(null);

    useEffect(() => {
        connnect();
        PubSub.publish(CONSTANTS.SIDEBAR_MENU_TOPIC, { mainMenu: MAINMENU.ConnectPlatform });
    }, []);

    async function connnect() {
        let fullParamUrl = window.location.search;
        if (fullParamUrl && fullParamUrl.indexOf("code=") > -1) {
            setBusy(true);
            setMessage("Đang tải dữ liệu..");
            const [err, data] = await PlatformServices.ConnectToPlatform(fullParamUrl);
            if (!err) {
                setBusy(false);
                setMessage("");
                props.history.push("/connect-platform-config/lazada");
            } else {
                setBusy(false);
                setMessage("");
            }
            _getStores();
        } else {
            _getStores();
        }
    }

    async function _handleConnectToLazada() {
        setBusy(true);
        setMessage("Đang kết nối...");
        const [err, data] = await PlatformServices.ConnectToPlatform(`?platform=${PLATFORM.Lazada}`);
        if (!err) {
            window.location.href = data;
            setBusy(false);
            setMessage("");
        } else {
            setBusy(false);
            setMessage("");
        }
    }

    async function _getStores() {
        setBusy(true);
        setMessage("Đang tải dữ liệu..");
        const [err, data] = await PlatformServices.PlatformStoresUser([PLATFORM.Lazada], STATUS_ACTIVE.All);
        if (!err) {
            setStores(data);
            setBusy(false);
            setMessage("");
        } else {
            setBusy(false);
            setMessage("");
        }
    }
    async function _handleRemoveStore(store) {
        ConfirmDialog("Xác nhận", "Bạn chắc chắn muốn xóa kết nối đến gian hàng đã chọn? ", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                setMessage("Đang xóa dữ liệu..");
                const [err, data] = await PlatformServices.DeleteConnectStore(store.id);
                if (!err) {
                    if (data) {
                        resolve({ title: "Thành công", msg: "Xóa kết nối gian hàng thành công" });
                        let storesUpdate = [...stores];
                        let idx = -1;
                        storesUpdate.map((item, i) => {
                            if (item.id == store.id) idx = i;
                        });
                        if (idx > -1) storesUpdate.splice(idx, 1);
                        setStores(storesUpdate);
                    } else {
                        reject(err);
                    }
                    setBusy(false);
                    setMessage("");
                } else {
                    Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                    setBusy(false);
                    setMessage("");
                }
            });
        });
    }

    async function _handleRefreshToken(store) {
        ConfirmDialog("Xác nhận", "Bạn chắc chắn muốn kết nối lại gian hàng đã chọn? ", () => {
            return new Promise(async (resolve, reject) => {
                setBusy(true);
                setMessage("Đang kết nối gian hàng..");
                const [err, data] = await PlatformServices.RefreshAccessToken({ id: store.id, shopId: store.shopId, platform: PLATFORM.Lazada });
                if (!err) {
                    resolve({ title: "Thành công", msg: "Kết nối gian hàng thành công" });
                    _getStores();
                    setBusy(false);
                    setMessage("");
                } else {
                    reject({ title: "Có lỗi", msg: err.data ? err.data : CONSTANTS.MSG_ERROR });
                    setBusy(false);
                    setMessage("");
                }
            });
        });
    }

    function _renderTable() {
        return stores && stores.length > 0 ? (
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h4 className="header-title">
                        <b>Gian hàng Lazada của bạn</b>
                    </h4>
                    <table className="tablesaw table mb-0 tablesaw-stack">
                        <thead>
                            <tr>
                                <th>Mã gian hàng</th>
                                <th>Gian hàng</th>
                                <th>Trạng thái</th>
                                <th>Ngày kết nối</th>
                                <th>Ngày hết hạn</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {stores.map((store, k) => {
                                return (
                                    <tr key={k} className={storeSelected && storeSelected.shopName == store.shopName ? "tr_selected" : ""}>
                                        <td>
                                            <strong className="store-name" onClick={() => setStorSelected(store)}>
                                                {store.shopId}
                                            </strong>
                                        </td>
                                        <td>
                                            <strong className="store-name" onClick={() => setStoreSelected(store)}>
                                                {store.shopName}
                                            </strong>
                                        </td>
                                        <td>{store.isActive ? <span className="label label-table label-success"> Đang hoạt động </span> : <span className="label label-table label-danger">Hết hạn</span>}</td>
                                        <td>
                                            <span>{store.connectDate}</span>
                                        </td>
                                        <td>{store.connectDateExpires ? <span>{store.connectDateExpires}</span> : null}</td>
                                        <td>
                                            {store.isActive ? null : (
                                                <button className="btn btn-custom btn-sm m-r-5" onClick={() => _handleRefreshToken(store)}>
                                                    <i className="fa fa-refresh"> Kết nối lại</i>
                                                </button>
                                            )}

                                            <button className="btn btn-danger btn-sm" onClick={() => _handleRemoveStore(store)}>
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        ) : null;
    }
    return (
        <React.Fragment>
            <Loading show={isBusy} msg={message} />
            <div className="row">
                <div className="col-sm-12 form-row card-box p-3">
                    <div className="col-12 col-lg-6">
                        <div className="lazada-channel--text">
                            <p className="mb-3 font-size-24px header-title">
                                Kết nối kênh bán hàng Lazada
                                {stores && stores.length > 0 ? <span> ({stores.length})</span> : null}
                            </p>
                            <p>
                                Kết nối với hệ thống bán hàng của Lazada, giúp đồng bộ tồn kho, giá sản phẩm, đơn hàng... giữa hai hệ thống <strong>RANUS-LAZADA</strong>.
                            </p>
                            {stores && stores.length > 0 ? (
                                <p className="sub-header text-success">
                                    {" "}
                                    <b>Hãy bắt đầu sử dụng các kênh bán hàng của Lazada để bán dược nhiều hàng và có nhiều doanh thu hơn.</b>
                                </p>
                            ) : (
                                <p className="sub-header text-danger">Kênh bán hàng Lazada của bạn chưa được kết nối. Vui lòng kết nối để bắt đầu sử dụng.</p>
                            )}
                        </div>
                        <div className="text-left">
                            <button className="btn btn-custom mt-3 mb-3" onClick={() => _handleConnectToLazada()}>
                                <span>
                                    {" "}
                                    <i className="fa fa-share-alt"></i> Kết nối kênh bán hàng{" "}
                                </span>
                            </button>
                            <p>
                                Bạn chưa có gian hàng?{" "}
                                <a href="https://sellercenter.lazada.vn/apps/register/landing" target="_blank">
                                    Đăng ký tại đây
                                </a>
                            </p>
                        </div>
                        <hr />
                        {_renderTable()}
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="salechannel--image">
                            <img src={Request.CdnURL() + "/images_system/sale-chanel.jpg"} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
