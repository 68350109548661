import React, { useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../based/Breadcrumbs";
import GeneralInformation from "./GeneralInformation";
import ProductInformation from "./ProductInformation";
import DiscountPlatform from "./DiscountPlatform";

const TabType = {
    GeneralInformation: 0,
    Product: 1,
    DiscountPlatform: 2,
};

const ListTab = [
    {
        value: TabType.GeneralInformation,
        label: "Thông tin chung",
    },
    {
        value: TabType.Product,
        label: "Sản phẩm",
    },
    {
        value: TabType.DiscountPlatform,
        label: "Giá sàn",
    },
];

function PlatformShopManagement(props) {
    const { shopId, platform } = props.match.params;
    const [tabIndex, setTabIndex] = useState(TabType.DiscountPlatform);

    function TabNavigation() {
        return (
            <ul className="nav nav-tabs tabs-bordered">
                {ListTab.map((item, index) => {
                    return (
                        <li key={index} className="nav-item">
                            <a className={tabIndex == item.value ? "nav-link active" : "nav-link btn-light"} onClick={() => setTabIndex(item.value)}>
                                <span>{item.label}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <Wrapper>
            <Breadcrumbs currentPage="Chi tiết kênh bán hàng" />
            <TabNavigation />
            {tabIndex == TabType.GeneralInformation && <GeneralInformation shopId={shopId} platform={+platform} />}
            {tabIndex == TabType.Product && <ProductInformation shopId={shopId} platform={+platform} />}
            {tabIndex == TabType.DiscountPlatform && <DiscountPlatform shopid={shopId} platform={+platform} />}
        </Wrapper>
    );
}

export default PlatformShopManagement;

const Wrapper = styled.div``;
