import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonModal from "../based/Modal";
import Request from "../based/Request";
import { Notify } from "../based/Notify";
import { NOTIFY, PLATFORM, PLATFORM_NAME } from "../based/Constants";
import TFUSelect from "../based/refactor/TFUSelect";
import PushingServices from "../based/services/PushingServices";
import Common from "../based/Common";
export const PushingPhotoshopProduct = {
    id: 0,
    photoshopFileId: 0,
    appProductId: 0,
    appProductName: "",
    appProductAvatar: "",
    pushingPhotoshopVariants: [],
};

const FileType = {
    Normal: 0,
    Case: 1,
};

export const PushingPhotoshopFile = {
    id: 0,
    name: "",
    fileUrl: "",
    thumbnail: "",
    fileProducts: [PushingPhotoshopProduct],
    appProductId: 0,
    appProductName: "",
    photoshopFileType: PLATFORM.Shopee,
    colorCode: "",
    photoshopFileTypeName: [],
    note: "",
    fileType: 0,
};

const PhotoshopFileList = [
    {
        value: PLATFORM.Shopee,
        text: PLATFORM_NAME.Shopee,
    },
    {
        value: PLATFORM.Lazada,
        text: PLATFORM_NAME.Lazada,
    },
    {
        value: PLATFORM.Tiktok,
        text: PLATFORM_NAME.Tiktok,
    },
    {
        value: PLATFORM.Facebook,
        text: PLATFORM_NAME.Facebook,
    },
    {
        value: PLATFORM.Zalo,
        text: PLATFORM_NAME.Zalo,
    },
];

function PushingFileModal({ id, isShowModal, onClose, onSave }) {
    const [isBusy, setIsBusy] = useState(false);
    const [model, setModel] = useState(PushingPhotoshopFile);
    const [options, setOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]); // [ { value: 1, label: "Product 1" }
    const [productIds, setProductIds] = useState([]);
    const [colors, setColors] = useState([]);

    console.log("model", model);

    useEffect(() => {
        getOptions();
        getProductOptions();
        getColor();
        if (id > 0) {
            getById(id);
        } else {
            setModel(PushingPhotoshopFile);
        }
    }, [isShowModal]);

    const getById = async (id) => {
        setIsBusy(true);
        let [err, data] = await PushingServices.GetPhotoshopFileById(id);
        if (!err && data) {
            setModel(data);
            if (data.fileProducts) {
                let ids = data.fileProducts.reduce((prev, item, idx) => {
                    if (prev.includes(item.appProductId)) return prev;
                    return [...prev, item.appProductId];
                }, []);
                setProductIds(ids);
            }
        }
        setIsBusy(false);
    };

    const _handleSave = async () => {
        setIsBusy(true);
        let md = { ...model, fileProducts: productIds.map((item) => ({ appProductId: item })) };
        let [err, data] = await PushingServices.CreateOrUpdatePhotoshopFile(md);
        if (!err && data) {
            Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Lưu thành công");
            onSave();
        } else {
            Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra");
        }
        setIsBusy(false);
    };

    const handleUploadFile = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (!file) return;
        setIsBusy(true);

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            Request.UploadPhotoshop(file).then((res) => {
                if (res && res.data) {
                    const { fileUrl, fileName } = res.data;
                    setModel({ ...model, fileUrl, name: fileName });
                    Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Upload PSD thành công");
                } else {
                    Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra");
                }
            });
        };

        setIsBusy(false);
    };

    const handleUploadThumbnail = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (!file) return;
        setIsBusy(true);
        Request.UploadImage(file).then((res) => {
            if (res && res.data) {
                const { imageUrl } = res.data;
                setModel({ ...model, thumbnail: imageUrl });
                Notify(NOTIFY.SUCCESS, NOTIFY.SUCCESS, "Upload thumbnail thành công");
            } else {
                Notify(NOTIFY.ERROR, NOTIFY.ERROR, "Có lỗi xảy ra");
            }
        });

        setIsBusy(false);
    };

    const getOptions = async () => {
        setIsBusy(true);
        let [err, data] = await PushingServices.GetDropdownProduct();
        if (!err && data) {
            setOptions(data);
        } else {
            setOptions([]);
        }
        setIsBusy(false);
    };

    const getProductOptions = async () => {
        setIsBusy(true);
        let [err, data] = await PushingServices.GetDropdownProduct();
        if (!err && data) {
            setProductOptions(data);
        } else {
            setProductOptions([]);
        }
        setIsBusy(false);
    };

    const getColor = async () => {
        setIsBusy(true);
        let [err, data] = await PushingServices.GetColorValue();
        if (!err && data) {
            setColors(data);
        } else {
            setColors([]);
        }
        setIsBusy(false);
    };

    return (
        <CommonModal show={isShowModal} size="lg" isBusy={isBusy} busyMsg={"Đang tải dữ liệu"} title={id > 0 ? "Cập nhật" : "Thêm mới"} onSave={_handleSave} onClose={() => onClose()}>
            <Wrapper>
                <div className="row my-2">
                    <div className="col-md-12">
                        <div className="form-group form-row">
                            <label htmlFor="" className="required">
                                Loại sản phẩm
                            </label>
                            <div className="list-file-type ml-3">
                                <div className="file-type">
                                    <input type="checkbox" checked={model.fileType == FileType.Normal} onChange={(e) => setModel({ ...model, fileType: FileType.Normal })} />
                                    <span>Áo thun/ Túi tote/ Bình nước/ Hộp bút</span>
                                </div>
                                <div className="file-type">
                                    <input
                                        type="checkbox"
                                        checked={model.fileType == FileType.Case}
                                        onChange={(e) => {
                                            setModel({ ...model, fileType: FileType.Case });
                                        }}
                                    />
                                    <span>Ốp lưng</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-row">
                            <label htmlFor="fileUrl" className="required">
                                File render hình
                            </label>
                            <input
                                type="file"
                                className="col-md-12"
                                name="fileUrl"
                                id="fileUrl"
                                placeholder="Chọn file"
                                onChange={(e) => {
                                    handleUploadFile(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-row">
                            <label htmlFor="thumbnail" className="required">
                                Hình mô tả
                            </label>
                            <input
                                type="file"
                                className="col-md-12"
                                name="thumbnail"
                                id="thumbnail"
                                placeholder="Hình mô tả"
                                onChange={(e) => {
                                    handleUploadThumbnail(e);
                                }}
                            />
                        </div>
                        <div className="thumbnail-avatar">{model.thumbnail && <img src={model.thumbnail} />}</div>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="form-group form-row col-md-12">
                        <label htmlFor="name" className="required">
                            Tên file
                        </label>
                        <input type="text" className="form-control" name="name" id="name" placeholder="Tên file" value={model.name} onChange={(e) => setModel({ ...model, name: e.target.value })} />
                    </div>
                </div>
                <div className="row my-2">
                    <div className="form-group form-row col-md-12">
                        <label htmlFor="" className="required col-md-12">
                            Sàn áp dụng
                        </label>
                        {PhotoshopFileList.map((item, index) => {
                            return (
                                <div key={index} className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="photoshopFileType"
                                        id={item.value}
                                        value={item.value}
                                        checked={Common.hasFlag(model.photoshopFileType, +item.value)}
                                        onChange={(e) => {
                                            //bitwise operator check
                                            let md = { ...model };
                                            let ptsFile = md.photoshopFileType;
                                            if (Common.hasFlag(ptsFile, +e.target.value)) {
                                                //remove flag
                                                ptsFile = ptsFile ^ +e.target.value;
                                            } else {
                                                //add flag
                                                ptsFile = ptsFile | +e.target.value;
                                            }
                                            setModel({ ...model, photoshopFileType: ptsFile });
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor={item.value}>
                                        {item.text}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {model.fileType == FileType.Normal && (
                    <React.Fragment>
                        <div className="row my-2">
                            <div className="form-group form-row col-md-6">
                                <label className="col-md-12" htmlFor="colorCode">
                                    Màu sản phẩm
                                </label>
                                <TFUSelect name="colorCode" className="col-md-12" id="colorCode" value={model.colorCode} options={colors} onChanged={(value) => setModel({ ...model, colorCode: value })} />
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="form-group form-row col-md-12">
                                <label htmlFor="fileProducts" className="required">
                                    Dòng sản phẩm có trong file
                                </label>
                                <div className="card-box">
                                    {productOptions &&
                                        productOptions.length &&
                                        productOptions.map((item, index) => {
                                            return (
                                                <div
                                                    className="card-item"
                                                    key={index}
                                                    onClick={() => {
                                                        let index = productIds.indexOf(+item.value);
                                                        if (index >= 0) {
                                                            productIds.splice(index, 1);
                                                        } else {
                                                            productIds.push(+item.value);
                                                        }
                                                        setProductIds([...productIds]);
                                                    }}
                                                >
                                                    <div className="check-item">
                                                        <input type="checkbox" value={productIds.includes(+item.value)} checked={productIds.includes(+item.value)} />
                                                    </div>
                                                    <div className="check-content">
                                                        <label>{item.label}</label>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </Wrapper>
        </CommonModal>
    );
}

export default PushingFileModal;

const Wrapper = styled.div`
    .file-type {
        display: flex;
        align-items: center;
    }

    .file-type span {
        color: #676767;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 6px;
    }

    .thumbnail-avatar {
        width: 200px;
        height: 200px;
    }

    .thumbnail-avatar img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .card-box {
        border: 1px solid;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    .card-item {
        width: calc(33.33% - 20px);
        margin: 10px;
        display: flex;
        align-items: center;
    }

    .check-content label {
        margin: 0 0 0 10px !important;
    }
`;
